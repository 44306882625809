////// ********||  Servicios  ||******** //////
    .recursos-humanos-cont{
        .sub-home {
            width: 80%;
            margin: 0 auto;
            p {
                line-height: 20px;
                margin-top: 6px;
            }
        }
    }


////// ********||  Servicio Detail  ||******** //////
    .servicio-pad {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    .foto-serv {
        img {
            width: 100%;
            vertical-align: middle;
        }
    }
    .info-servicio {
        padding: 1rem;
        h1 {
            font-family: $poppins-medium;
            font-size: 30px;
            line-height: 30px;
            color: $negro;
            margin: 0rem;
            padding: 0rem;
            margin-bottom: 2rem;
            text-transform: uppercase;
        }
        p {
            font-family: $poppins-light;
            font-size: 16px;
            line-height: 20px;
            color: $negro;
            padding: 0rem;
            margin: 0rem;
            margin-bottom: 1.2rem;
            strong, b {
                font-family: $poppins-medium;
                font-weight: initial;
            }
        }
        ul {
            padding-top: 1rem;
            li {
                font-family: $poppins-light;
                font-size: 16px;
                line-height: 16px;
                color: $negro;
                padding: 0rem;
                margin: 0rem;
                margin-bottom: 16px;
                background-image: url('../images/check.svg');
                background-size: 13px;
                background-repeat: no-repeat;
                background-position-x: 0%;
                background-position-y: 40%;
                padding-left: 2rem;
                p {
                    width: 16px;
                    height: 16px;
                    background-size: 16px;
                    background-repeat: no-repeat;
                    display: inline-block;
                    margin-bottom: 0rem;
                }
            }
        }
    }


    .display-none {
        display: none;
    }
    .ocultarflaspirante {
        #flempresa {
            display: none;
        }
    }
    .ocultarflempresa {
        #flaspirante {
            display: none;
        }
    }
    .tabs-login {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .p-tab {
            width: 49%;
            padding: 2rem !important;
            display: flex;
            align-items: center;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            p{
                font-family: $poppins-medium;
                font-size: 14px;
                line-height: 14px;
                color: #727272;
                margin: 0rem;
                padding: 0rem;
                text-transform: uppercase;
                transition: all 300ms;
            }
            i {
                margin-right: 4px;
                color: #727272;
                transition: all 300ms;
            }
            .p-tab-div {
                width: 50px;
                height: 3px;
                background-color: $rojo;
                position: absolute;
                bottom: 18px;
                left: 50%;
                opacity: 0;
                -ms-transform: translate(-16%, 21px); /* IE 9 */
                -webkit-transform: translate(-16%, 21px); /* Chrome, Safari, Opera */
                transform: translate(-16%, 21px);
                transition: all 300ms;
            }
            .div-mostrar{
                opacity: 1;
                -ms-transform: translate(-16%, 0%); /* IE 9 */
                -webkit-transform: translate(-16%, 0%); /* Chrome, Safari, Opera */
                transform: translate(-16%, 0%);
            }
        }
        .active {
            p, i {
                color: $rojo;
            }
        }
    }

////// ********||  Main Footer  ||******** //////

    footer {
        padding: 3rem 0rem;
        padding-bottom: 0rem;
        background-color: $azul;
        .footer-cont {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 2rem;
            border-bottom: 1px solid white;
            .desc-footer {
                width: 35%;
                @media (max-width: 993px) {
                    width: 100%;
                    text-align: center;
                    margin-bottom: 2rem;
                }
                img {
                    margin-bottom: 2rem;
                    vertical-align: middle;
                }
                p {
                    font-family: $poppins-light;
                    font-size: 12px;
                    line-height: 16px;
                    color: #c5c5c5;
                    margin: 0rem;
                    margin-bottom: 12px;
                }
            }
            .info-footer {
                width: 65%;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                @media (max-width: 993px) {
                    width: 100%;
                }
                .menu-div {
                    width: 33.33%;
                    padding: 0rem 0.5rem;
                    @media (max-width: 993px) {
                        width: 100%;
                        display: none;
                        &:last-of-type {
                            display: block;
                        }
                    }
                    p {
                        font-family: $poppins-light;
                        font-size: 12px;
                        line-height: 12px;
                        color: #c5c5c5;
                        padding-left: 1rem;
                        margin: 0rem;
                        margin-bottom: 12px;
                        a {
                            color: #c5c5c5;
                            transition: all 300ms;
                            &:hover {
                                color: $rojo;
                            }
                        }
                        &:before {
                            content: '\2023';
                            font-size: 20px;
                            margin-right: 6px;
                            vertical-align: middle;
                        }
                        &:first-of-type {
                            font-family: $poppins-semibold;
                            font-size: 14px;
                            line-height: 14px;
                            color: white;
                            padding: 0rem;
                            margin-bottom: 1.2rem;
                            &:before {
                                content: '';
                            }
                        }
                    }
                }
                .direccion-footer {
                    p {
                        font-family: $poppins-light !important;
                        font-size: 12px !important;
                        line-height: 12px !important;
                        color: #c5c5c5 !important;
                        padding-left: 1rem;
                        margin: 0rem;
                        margin-bottom: 8px !important;
                        &:before {
                            content: '';
                        }
                    }
                }
                .boletin {
                    p {
                        padding: 0rem;
                        line-height: 16px;
                        @media (max-width: 993px) {
                            margin-bottom: 8px !important;
                            text-align: center;
                        }
                    }
                    form {
                        position: relative;
                        border-radius: 6px;
                        overflow: hidden;
                        input {
                            font-size: 14px;
                            line-height: 14px;
                            color: #c5c5c5;
                            width: 100%;
                            height: auto;
                            padding: 10px 10px;
                            padding-right: 40px;
                            border: 0px;
                            border-radius: 6px;
                            outline: none;
                            background-color: white;
                        }
                        button {
                            width: 40px;
                            height: 39px;
                            color: white;
                            font-size: 20px;
                            position: absolute;
                            top: 0px;
                            right: 0px;
                            border: 0px;
                            background-color: $rojo;
                            transition: all 300ms;
                            cursor: pointer;
                            &:hover {
                                background-color: #bb0d31;
                            }
                        }
                    }
                }
            }
        }
        .footer-menu-div {
            width: 100%;
            padding: 1.4rem 0rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
                font-family: $poppins-light;
                font-size: 12px;
                line-height: 16px;
                color: #c5c5c5;
                margin: 0rem;
                margin-bottom: 0px;
            }
            a {
                font-family: $poppins-light;
                font-size: 12px;
                line-height: 16px;
                color: #c5c5c5;
                margin: 0rem;
                margin-bottom: 0px;
                strong {
                    font-family: $poppins-semibold;
                }
            }
            ul {
                display: flex;
                flex-wrap: wrap;
                li {
                    width: auto;
                    outline: none;
                    display: flex;
                    align-items: center;
                    border-top: 2px solid transparent;
                    transition: all 300ms;
                    a{
                        font-family: $poppins;
                        font-size: 14px;
                        color: white;
                        text-align: center;
                        padding: 0px 24px;
                        line-height: 66px;
                        outline: none;
                        transition: all 300ms;
                        &:hover {
                            color: $rojo;
                        }
                    }
                }
            }
        }
    }
    .footer-div {
        background-color: $azul-02;
        padding-top: 4rem;
        padding-bottom: 3rem;
        @media (max-width: 769px) {
            padding-top: 2rem;
            text-align: center;
        }
        .sub-footer {
            font-family: $poppins;
            font-size: 14px;
            line-height: 14px;
            color: $naranja;
            margin: 0px;
            margin-bottom: 14px;
            text-transform: uppercase;
            @media (max-width: 650px) {
                padding-top: 2rem;
            }
        }
        p {
            font-family: $poppins;
            font-size: 14px;
            line-height: 20px;
            // color: white;
            color: black;
            margin: 0px;
            margin-bottom: 8px;
            text-transform: uppercase;
            a {
                color: black;
                transition: all 300ms;
                i {
                    color: black;
                    font-size: 14px;
                    margin-right: 5px;
                    vertical-align: middle;
                }
                &:hover {
                    color: white;
                }
            }
        }
    }
    .pad-footer {
        padding-left: 6rem;
        @media (max-width: 1700px) {
            padding-left: 4rem;
        }
        @media (max-width: 1500px) {
            padding-left: 2rem;
        }
        @media (max-width: 1100px) {
            padding-left: 1rem;
        }
    }
    .logo-footer {
        width: 70%;
        padding-bottom: 24px;
        vertical-align: middle;
        @media (max-width: 650px) {
            width: 34%;
        }
    }
    .redes-footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        a {
            font-size: 20px;
            display: inline-block;
            color: #c5c5c5;
            margin: 0rem 10px;
            padding: 2px;
            text-align: center;
            transition: all 300ms;
            &:hover {
                color: $rojo;
            }
            &:first-of-type {
                margin-left: 6px;
            }
            &:last-of-type {
                margin-right: 0px;
            }
        }
        @media (max-width: 769px) {
            padding-top: 1rem;
            justify-content: center;
        }
    }
    .menu-footer {
        // p:before {
        //     content: '\26AC';
        //     color: white;
        //     display: inline-block;
        //     vertical-align: middle;
        //     font-size: 16px;
        //     line-height: 10px;
        //     margin-right: 8px;
        // }
        @media (max-width: 992px) {
            text-align: center;
            p {
                font-size: 14px;
                line-height: 14px;
                padding: 0rem 1rem;
                display: inline-block;
                margin-bottom: 1rem;
            }
        }
    }

////// ********||  Legal Footer  ||******** //////
    .footer {
        padding: 1.2rem 0rem;
        background-color: $azul-02;
        overflow: hidden;
        border-top: 1px solid black;
        @media (max-width: 769px) {
            text-align: center;
        }
        p {
            font-family: $poppins;
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 1px;
            color: white;
            margin: 0rem;
            display: inline-block;
            text-transform: uppercase;
            a {
                color: white;
                transition: all 300ms;
                &:hover {
                    color: black;
                }
            }
            @media (max-width: 769px) {
                width: 100%;
                line-height: 14px;
            }
        }
        img {
            width: 60px;
            float: right;
            @media (max-width: 650px) {
                display: none;
            }
        }
    }
    .by-footer {
        float: right;
        span{
             text-transform: lowercase !important;
        }
        @media (max-width: 769px) {
            float: none;
        }
    }
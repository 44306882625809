@mixin arial_regular($size: 1rem){
    font-family: "arial";
    font-size: $size;
}

@font-face {
    font-family:'Lato Bold';
    src: url('../fonts/lato/Lato Bold.eot');
    src: url('../fonts/lato/Lato Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/lato/Lato Bold.woff2') format('woff2'),
        url('../fonts/lato/Lato Bold.woff') format('woff'),
        url('../fonts/lato/Lato Bold.otf') format('opentype'),
        url('../fonts/lato/Lato Bold.svg#Lato Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@font-face {
    font-family:'Lato Black Italic';
    src: url('../fonts/lato/Lato Black Italic.eot');
    src: url('../fonts/lato/Lato Black Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/lato/Lato Black Italic.woff2') format('woff2'),
        url('../fonts/lato/Lato Black Italic.woff') format('woff'),
        url('../fonts/lato/Lato Black Italic.otf') format('opentype'),
        url('../fonts/lato/Lato Black Italic.svg#Lato Black Italic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@font-face {
    font-family:'Lato';
    src: url('../fonts/lato/Lato Regular.eot');
    src: url('../fonts/lato/Lato Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/lato/Lato Regular.woff2') format('woff2'),
        url('../fonts/lato/Lato Regular.woff') format('woff'),
        url('../fonts/lato/Lato Regular.otf') format('opentype'),
        url('../fonts/lato/Lato Regular.svg#Lato Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@font-face {
    font-family:'Lato Light';
    src: url('../fonts/lato/Lato Light.eot');
    src: url('../fonts/lato/Lato Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/lato/Lato Light.woff2') format('woff2'),
        url('../fonts/lato/Lato Light.woff') format('woff'),
        url('../fonts/lato/Lato Light.otf') format('opentype'),
        url('../fonts/lato/Lato Light.svg#Lato Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@font-face {
    font-family:'Lato Light Italic';
    src: url('../fonts/lato/Lato Light Italic.eot');
    src: url('../fonts/lato/Lato Light Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/lato/Lato Light Italic.woff2') format('woff2'),
        url('../fonts/lato/Lato Light Italic.woff') format('woff'),
        url('../fonts/lato/Lato Light Italic.otf') format('opentype'),
        url('../fonts/lato/Lato Light Italic.svg#Lato Light Italic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}
@font-face {
    font-family:'Roboto Thin';
    src: url('../fonts/roboto/Roboto Thin.eot');
    src: url('../fonts/roboto/Roboto Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto Thin.woff2') format('woff2'),
        url('../fonts/roboto/Roboto Thin.woff') format('woff'),
        url('../fonts/roboto/Roboto Thin.otf') format('opentype'),
        url('../fonts/roboto/Roboto Thin.svg#Roboto Thin') format('svg');
    font-weight: 250;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@font-face {
    font-family:'Roboto Light';
    src: url('../fonts/roboto/Roboto Light.eot');
    src: url('../fonts/roboto/Roboto Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto Light.woff2') format('woff2'),
        url('../fonts/roboto/Roboto Light.woff') format('woff'),
        url('../fonts/roboto/Roboto Light.otf') format('opentype'),
        url('../fonts/roboto/Roboto Light.svg#Roboto Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@font-face {
    font-family:'Roboto';
    src: url('../fonts/roboto/Roboto.eot');
    src: url('../fonts/roboto/Roboto.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto.woff2') format('woff2'),
        url('../fonts/roboto/Roboto.woff') format('woff'),
        url('../fonts/roboto/Roboto.otf') format('opentype'),
        url('../fonts/roboto/Roboto.svg#Roboto') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family:'Roboto Medium';
    src: url('../fonts/roboto/Roboto Medium.eot');
    src: url('../fonts/roboto/Roboto Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto Medium.woff2') format('woff2'),
        url('../fonts/roboto/Roboto Medium.woff') format('woff'),
        url('../fonts/roboto/Roboto Medium.otf') format('opentype'),
        url('../fonts/roboto/Roboto Medium.svg#Roboto Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@font-face {
    font-family:'Roboto Bold';
    src: url('../fonts/roboto/Roboto Bold.eot');
    src: url('../fonts/roboto/Roboto Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto Bold.woff2') format('woff2'),
        url('../fonts/roboto/Roboto Bold.woff') format('woff'),
        url('../fonts/roboto/Roboto Bold.otf') format('opentype'),
        url('../fonts/roboto/Roboto Bold.svg#Roboto Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}


@font-face {
    font-family:'Roboto Black';
    src: url('../fonts/roboto/Roboto Black.eot');
    src: url('../fonts/roboto/Roboto Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto Black.woff2') format('woff2'),
        url('../fonts/roboto/Roboto Black.woff') format('woff'),
        url('../fonts/roboto/Roboto Black.otf') format('opentype'),
        url('../fonts/roboto/Roboto Black.svg#Roboto Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@font-face {
    font-family:'Poppins Light';
    src: url('../fonts/poppins/Poppins Light.eot');
    src: url('../fonts/poppins/Poppins Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/poppins/Poppins Light.woff2') format('woff2'),
        url('../fonts/poppins/Poppins Light.woff') format('woff'),
        url('../fonts/poppins/Poppins Light.svg#Poppins Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@font-face {
    font-family:'Poppins';
    src: url('../fonts/poppins/Poppins.eot');
    src: url('../fonts/poppins/Poppins.eot?#iefix') format('embedded-opentype'),
        url('../fonts/poppins/Poppins.woff2') format('woff2'),
        url('../fonts/poppins/Poppins.woff') format('woff'),
        url('../fonts/poppins/Poppins.otf') format('opentype'),
        url('../fonts/poppins/Poppins.svg#Poppins') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family:'Poppins Medium';
    src: url('../fonts/poppins/Poppins Medium.eot');
    src: url('../fonts/poppins/Poppins Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/poppins/Poppins Medium.woff2') format('woff2'),
        url('../fonts/poppins/Poppins Medium.woff') format('woff'),
        url('../fonts/poppins/Poppins Medium.svg#Poppins Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@font-face {
    font-family:'Poppins Semibold';
    src: url('../fonts/poppins/Poppins SemiBold.eot');
    src: url('../fonts/poppins/Poppins SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/poppins/Poppins SemiBold.woff2') format('woff2'),
        url('../fonts/poppins/Poppins SemiBold.woff') format('woff'),
        url('../fonts/poppins/Poppins SemiBold.svg#Poppins SemiBold') format('svg');
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@font-face {
    font-family:'Poppins Bold';
    src: url('../fonts/poppins/Poppins Bold.eot');
    src: url('../fonts/poppins/Poppins Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/poppins/Poppins Bold.woff2') format('woff2'),
        url('../fonts/poppins/Poppins Bold.woff') format('woff'),
        url('../fonts/poppins/Poppins Bold.svg#Poppins Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}
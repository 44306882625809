////// ******** Main Menu ******** //////
header {
  // background-color: rgba(black, 0.7);
  position: fixed;
  width: 100%;
  z-index: 1002;
  .btn-menu {
    color: $rojo;
  }
  .nav-menu {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // align-items: center;
    li {
      width: auto;
      outline: none;
      display: flex;
      align-items: center;
      border-top: 2px solid transparent;
      transition: all 300ms;
      a {
        font-family: $poppins;
        font-size: 14px;
        color: white;
        text-align: center;
        padding: 0px 24px;
        line-height: 60px;
        outline: none;
        transition: all 300ms;
        &:hover {
          background-color: transparent;
        }
      }
      &:hover {
        border-top: 2px solid $rojo;
      }
      @media (max-width: 1600px) {
        // width: 12% !important;
        a {
          padding: 0px 14px !important;
        }
      }
      @media (max-width: 1400px) {
        a {
          padding: 0px 12px !important;
        }
      }
      @media (max-width: 1160px) {
        a {
          // font-size: 12px;
          padding: 0px 6px !important;
        }
      }
      img {
        width: 100%;
        margin-top: -3px;
        vertical-align: middle;
        max-width: 220px;
        @media (max-width: 1260px) {
          max-width: 140px;
        }
        @media (max-width: 1100px) {
          max-width: 115px;
        }
      }
    }
    .active {
      background-color: transparent;
      border-top: 2px solid $rojo;
      a {
        // color: $azul;
        background-color: transparent;
      }
    }
    .li-logo {
      border-top: 2px solid transparent !important;
      img {
        width: 80%;
        vertical-align: middle;
      }
    }
    .redes-header {
      a {
        width: auto;
        display: inline-block;
        padding: 0px 8px !important;
        // @media (max-width: 1213px) {
        //     padding:0px 10px;
        // }
      }
    }
  }

  nav {
    background-color: transparent;
    // height: 80px;
    // line-height: 80px;
    height: auto;
    line-height: initial;
    // @media (max-width: 1213px) {
    //     height: 64px;
    //     line-height: 64px;
    // }
    // box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    box-shadow: none;
  }
  .nav-wrapper {
    background-color: transparent;
    text-align: center;
  }

  .side-nav {
    overflow: initial;
    height: 100vh;
    .search {
      margin-top: 1px;
      padding: 1px 0 0 0;
      z-index: 2;

      .search-mobile {
        width: inherit;
        margin-bottom: 0;
        padding: 20px 20px 20px 50px;
      }
      .label-icon {
        i {
          color: rgba(117, 117, 117, 0.7);
        }
      }
    }
    li {
      background-color: transparent;
      // border: 1px solid transparent;
      a {
        font-family: $poppins;
        font-size: 14px;
        line-height: 14px;
        height: auto;
        color: $negro;
        padding: 1.6rem 2rem;
        text-align: left;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
    .active {
      background-color: $azul;
      a {
        color: white;
      }
    }
  }
}
.show-menu {
  transform: translateY(0%);
  -ms-transform: translateY(0%); /* IE 9 */
  -webkit-transform: translateY(0%); /* Chrome, Safari, Opera */
}

.menu-clip {
  display: flex;
  width: 100%;
  transition: all 300ms;
  .nav-menu {
    width: 70%;
  }
  .registro-menu {
    width: 30%;
  }
}

.registro-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-bottom-left-radius: 30px;
  @media (max-width: 1014px) {
    display: none;
  }
  .logo-reg {
    display: flex;
    align-items: center;
    margin: 0px 2rem;
    // @media (max-width: 1350px) {
    //     margin: 0rem 10px;
    // }
    @media (max-width: 1540px) {
      margin: 0rem 6px;
    }
    img {
      width: 36px;
      margin-right: 6px;
      @media (max-width: 1540px) {
        width: 28px;
        margin-right: 4px;
      }
    }
    p {
      width: auto;
      font-family: $poppins;
      font-size: 12px;
      line-height: 14px;
      color: #333333;
      margin: 0rem;
      text-align: left;
    }
  }
  .btn-login-menu {
    display: block;
    width: auto;
    font-family: $poppins;
    font-size: 12px;
    line-height: 13px;
    color: white;
    padding: 8px 16px;
    margin: 0rem 2rem;
    border-radius: 20px;
    background-color: $azul;
    transition: all 300ms;
    &:hover {
      background-color: #136cab;
    }
    @media (max-width: 1350px) {
      margin: 0rem 10px;
    }
  }
  .cuenta-link {
    font-family: $poppins;
    font-size: 14px;
    line-height: 14px;
    color: $negro;
    margin: 0rem;
    display: flex;
    align-items: center;
    padding: 0rem 1rem;
    transition: all 300ms;
    i {
      font-size: 18px;
      margin-right: 4px;
    }
    &:hover {
      color: $rojo;
    }
  }
  .separador-menu {
    width: 2px;
    height: 16px;
    background-color: #5d5d5d;
    margin: 0px 4px;
  }
}

.dropdown-content {
  outline: none;
  width: 215px !important;
  // height: 276px !important;
  top: 70px !important;
  left: 8px !important;
  overflow-y: initial;
  background-color: white;
  padding: 0rem 0rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
  &::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    // background-color: #c25333;
    background-color: $azul;
    outline: none;
    width: 100px;
  }
  li {
    width: 100% !important;
    background-color: white !important;
    line-height: 16px !important;
    height: auto !important;
    min-height: initial !important;

    position: relative;
    text-align: center;
    &:hover {
      border-top: 2px solid transparent !important;
      .subdrop {
        opacity: 1;
        transition: opacity 300ms;
        display: block !important;
      }
    }
    a {
      color: $negro !important;
      line-height: 16px !important;
      width: 100%;
      padding: 1rem 8px !important;
      &:hover {
        color: $rojo !important;
      }
    }
  }
}

////// ******** Small Menu ******** //////
#sidenav-overlay {
  z-index: 49;
}

.logo-small-menu {
  width: 100px;
  vertical-align: middle;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%); /* IE 9 */
  -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
  transform: translate(-50%, -50%);
  display: none;
  @media (max-width: 992px) {
    display: block;
  }
}

.whats-small {
  width: 24px;
  vertical-align: middle;
  position: absolute;
  top: 50%;
  right: 20px;
  -ms-transform: translateY(-50%); /* IE 9 */
  -webkit-transform: translateY(-50%); /* Chrome, Safari, Opera */
  transform: translateY(-50%);
  display: none;
  @media (max-width: 992px) {
    display: block;
  }
}
.info-small {
  padding-top: 2rem;
  padding-bottom: 1rem;
  margin-top: 8px;
  border-top: 2px solid $amarillo;
  a {
    font-family: $roboto-bold !important;
    font-size: 12px !important;
    line-height: 12px !important;
    color: $azul !important;
    padding: 0rem 2rem !important;
    padding-bottom: 32px !important;
    position: relative;
    div {
      width: 30px;
      height: 1px;
      background-color: $amarillo;
      position: absolute;
      top: 20px;
      left: 30px;
    }
    i {
      font-size: 14px !important;
      line-height: 14px !important;
      color: $azul-claro !important;
      height: auto !important;
      margin: 0rem !important;
      vertical-align: middle;
      // position: absolute;
      // top: calc(50% - 7px);
      // left: 6px;
    }
  }
}
.userView {
  position: relative;
  margin-bottom: 0rem;
  .background {
    img {
      vertical-align: middle;
    }
  }
  .correo-side {
    position: absolute;
    bottom: 0px;
    left: 8px;
    padding-left: 8px !important;
    padding-bottom: 1rem;
    a {
      padding: 0rem;
      padding: 0.4rem;
      img {
        width: 20px;
        vertical-align: middle;
        margin-right: 4px;
      }
    }
  }
}
.logo-sidenav {
  width: 100%;
  padding: 2rem 0rem;
  padding-left: 14px !important;
  position: absolute;
  top: 0px;
  left: 0px;
  img {
    width: 130px;
    vertical-align: middle;
  }
}
.login-small {
  width: 100%;
  overflow: hidden;
  .login-p {
    width: 100%;
    background-color: $negro;
    padding: 0rem 20px;
    a {
      font-family: $roboto;
      font-size: 12px;
      line-height: 34px;
      color: white;
      display: inline-block;
      transition: all 300ms;
      padding: 0 16px;
      i {
        font-size: 16px;
        color: white;
        transition: all 300ms;
        margin-right: 4px;
        display: inline-block;
        vertical-align: middle;
      }
      &:hover {
        color: $naranja;
        i {
          color: $naranja;
        }
      }
    }
    .last-a {
      padding-left: 25px;
      position: relative;
      i {
        font-size: 14px;
        top: 3px;
      }
    }
    div {
      width: 1px;
      height: 14px;
      background-color: white;
      position: absolute;
      top: 9px;
      left: 11px;
    }
  }
  .cart-p {
    // background-color: #999999;
    background-color: #e8e8e8;
    transition: all 300ms;
    text-align: left;
    padding: 0rem;
    a {
      font-family: $roboto-medium;
      font-size: 16px;
      line-height: 16px;
      padding: 1.6rem 2rem;
      // padding-left: 5px;
      color: $negro;
      width: 100%;
      i {
        color: $negro;
        line-height: 16px;
        height: auto;
      }
    }
    &:hover {
      background-color: #bfbfbf;
      a {
        color: white;
        i {
          color: white;
        }
      }
    }
  }
}

////// ******** Fixed Menu ******** //////
.menu-placeholder {
  width: 100%;
  height: 95px;
  background-color: white;
  transition: all 300ms;
  @media (max-width: 993px) {
    height: 57px;
  }
}
.m-fixed {
  position: fixed;
  /**/
  .nav-menu {
    li {
      a {
        color: $negro;
      }
    }
    .buscador {
      height: 60px;
      form {
        input {
          height: 60px;
        }
      }
    }
  }
  .menu-clip {
    background-color: white;
  }
}
.place-fixed {
  height: 94px;
}

.cabecera-cont {
  background-color: rgba(black, 0.6);
  width: 100%;
}
.cabecera-menu {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 8px 0px;
  a {
    font-family: $poppins;
    font-size: 11px;
    line-height: 10px;
    color: white;
    padding: 0rem 1rem;
    transition: all 300ms;
    display: flex;
    align-items: center;
    // &:hover {
    //     color: $rojo;
    // }
    i {
      font-size: 14px;
      margin-right: 4px;
    }
  }
  p {
    color: white;
    margin: 0rem 4px;
  }
  div {
    width: 14%;
    text-align: center;
    @media (max-width: 993px) {
      width: 100%;
      display: none;
    }
  }
  @media (max-width: 993px) {
    display: none;
  }
}
.idiomas-small {
  text-align: right;
  padding: 0rem 2rem;
  a {
    width: auto;
    font-size: 12px;
    letter-spacing: 1px;
    color: white;
    margin: 0px 12px;
    padding: 0rem !important;
    display: inline-block !important;
    text-transform: uppercase;
  }
  .activo {
    color: $azul;
  }
}

@media #{$medium-and-down} {
  .information {
    display: none;
  }

  header {
    background-color: white;
  }
}
.information {
  .container-info {
    width: 15em;
    position: absolute;
    right: 180px;
    z-index: -1;
  }
  .phone {
    width: 15em;
    background: white;
    margin: auto;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0 0 5px 5px;
    padding: 0.5em 0 1em 0;
    margin-top: 0;
    h6 {
      svg {
        color: rgb(228, 34, 74);
        margin-right: 5px;
      }
      color: rgb(38, 38, 38);
      font-weight: bold;
      text-align: center;
      margin: 5px 0 0 0;
    }
    h3 {
      font-size: 17px;
      color: #1c5882;
      font-weight: bold;
      text-align: center;
      margin:5px 0 0 0;
    }
  }
}

.menu-clip {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

nav {
  background-color: white;
}

@media #{$medium-and-down} {
  nav {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
  }
}

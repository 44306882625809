////// ********||       Info detail       ||******** //////
.back-gris {
  background-color: #e5e4e5;
}
.vacante-pad {
  padding-top: 10rem;
  padding-bottom: 4rem;
}
.buscador-empleos {
  position: relative;
  .buscador-cont {
    border-radius: 10px;
    transform: translate(-50%, 70%);
    -ms-transform: translate(-50%, 70%); /* IE 9 */
    -webkit-transform: translate(-50%, 70%); /* Chrome, Safari, Opera */
    box-shadow: 0px 4px 6px 0px #cacaca;
    @media (max-width: 769px) {
      position: absolute;
    }
  }
}
.rayo {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 70px;
}
.botones-vacante {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding-bottom: 1.6rem;
  @media (max-width: 769px) {
    justify-content: center;
    .btn-rojo {
      padding: 12px 14px;
    }
  }
  button {
    border-radius: 3px;
    margin: 0px 4px;
    cursor: pointer;
  }
}
.btn-otras-vacantes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 993px) {
    a {
      margin-bottom: 1rem;
    }
  }
}

@media #{$extra-large-and-up} {
  .tab {
    display: none;
  }
  .imagen-vacante {
    margin-top: 0 !important;
  }
}

.imagen-vacante {
  margin-bottom: 3em;
  margin-top: -2em;
  img {
    width: 100%;
    vertical-align: middle;
    margin-bottom: 2rem;
  }
  h6 {
    font-family: $poppins;
    font-size: 16px;
    line-height: 16px;
    color: $azul;
    padding: 0rem;
    margin-top: 1rem;
    margin-bottom: 10px;
  }
}
@media #{$medium-and-down} {
  .extra-large-and-up {
    margin-bottom: 0;
    margin-top: 0;
  }
}
.detalle-vacante {
  background-color: #f2f2f2;
  position: relative;
  padding: 2rem;
  padding-bottom: 2rem;
  @media (max-width: 993px) {
    padding: 2rem;
  }
  h1 {
    font-family: $poppins-medium;
    font-size: 28px;
    line-height: 46px;
    color: $azul;
    padding: 0rem;
    margin: 0rem;
    margin-bottom: 2rem;
  }
  h3 {
    font-family: $poppins-medium;
    font-size: 18px;
    line-height: 24px;
    color: $azul;
    padding: 0rem;
    margin: 0rem;
    margin-bottom: 18px;
  }
  p {
    font-family: $poppins-light;
    font-size: 15px;
    line-height: 24px;
    color: #262626;
    margin: 0rem;
    padding: 0rem;
    margin-bottom: 6px;
    text-align: justify;
    span {
      font-family: $poppins-medium;
    }
  }
  .detalle-seccion {
    padding-top: 2rem;
    padding-bottom: 1rem;
    a,
    a:hover {
      color: rgb(228, 34, 74);
    }
    .btn-rojo,
    .btn-rojo:hover {
      color: white;
    }
  }
}

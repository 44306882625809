.body-perfil {
  background: #d2dee6;
}
.detalle-perfil {
  background: white;
  border-radius: 10px;
  padding-bottom: 6%;
  padding-left: 1%;
  padding-right: 1%;
  margin-top: 6%;
  .titulo-perfil {
    margin-top: 1%;
  }
  .text-icon {
    vertical-align: super;
  }
  .list-cv,
  .list-video {
    margin-bottom: 20px !important;
    padding-left: 0 !important;
    .list-group-item {
      border-bottom-right-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }
  }
  h2 {
    margin: 1%;
    font-size: 36px;
    color: #1c5882;
    text-transform: uppercase;
    font-weight: 600;
  }
  h3 {
    font-family: $poppins-medium;
    font-size: 32px;
    line-height: 32px;
    color: $azul;
    padding: 0rem;
  }
  h4 {
    color: $azul;
    font-size: 20px;
    font-family: $poppins-medium;
  }
  h6 {
    font-family: $poppins-medium;
    font-size: 18px;
    line-height: 24px;
    color: $azul;
    padding: 0rem;
    margin: 0rem;
  }
  hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
  }
  p {
    font-family: $poppins-light;
    font-size: 15px;
    line-height: 24px;
    color: #262626;
    margin: 0rem;
    padding: 0rem;
    margin-bottom: 6px;
    text-align: justify;
    span {
      font-family: $poppins-medium;
    }
  }
  .panel {
    border-color: #ddd;
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    .list-group {
      margin: 0;
      .list-group-item {
        border-top-width: 0;
        border-width: 1px 0;
        border-radius: 0;
        padding: 5px 15px;
        position: relative;
        display: block;
        margin-bottom: -1px;
        background-color: #fff;
        border: 1px solid #ddd;
      }
    }
  }
  .panel-default {
    border-color: #ddd;
    .panel-heading {
      color: #333;
      background-color: #f5f5f5;
      border-color: #ddd;
      padding: 10px 15px;
      border-bottom: 1px solid transparent;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
    }
  }
  .list-group {
    margin: 0;
    .list-group-item {
      border-top-width: 0;
      border-width: 1px 0;
      border-radius: 0;
      padding: 5px 15px;
      position: relative;
      display: block;
      margin-bottom: -1px;
      background-color: #fff;
      border: 1px solid #ddd;
    }
  }
  .detalle-cv {
    h6 {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
    }
    p {
      font-size: 14px;
    }
  }
}

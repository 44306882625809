.cover{
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    background-size: cover;
}

.bgParallax{
    background-attachment: fixed !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
}

.full_screen{
    @extend .cover;
    width: 100%;
    height: 100%;
}
.table-cell{
    display: table;
    width: 100%;
    height: 100%;

    .cell{
        display: table-cell;
        vertical-align: middle;
    }
}

.relative{ position: relative !important; }
.absolute{ position: absolute !important; }
.fixed{ position: fixed !important; }

.uppercase{ text-transform: uppercase !important; }
.lowercase{ text-transform: lowercase !important; }
.capitalize{ text-transform: capitalize !important; }

.left{ float: left !important; }
.right{ float: right !important; }

.clear{ clear: both !important; }

.center{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.inline-block{ display: inline-block !important; }
.block{ display: block !important; }

.pointer{ cursor: pointer !important; }
.default{ cursor: default !important; }

.link_all{
    @extend .absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.no-padd-right{ padding-right: 0px; }
.no-padd-left{ padding-left: 0px; }

.full_width{
    width: 100% !important;
}

.no-padding{
    padding: 0 !important;
}

.no-margin{
    margin: 0 !important;
}

.delete{
    @include itransition;
    cursor: pointer;

    &:hover{
        color: $Red;
        @include scale(1.3, 1.3);
    }
}

//FLEX Classes

.display-flex{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.flex-direction-row{
    -ms-flex-direction: row;
    flex-direction: row;
}

.flex-direction-column{
    -ms-flex-direction: column;
    flex-direction: column;
}

.flex-wrap{
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.justify-content-center{
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;

    -ms-flex-pack: justify;
}

.justify-content-between{
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
}

.flex-align-baseline{
    -webkit--items: baseline !important;
    -moz-align-items: baseline !important;
    -ms-align-items: baseline !important;
    align-items: baseline !important;
}

.flex-align-center{
    -webkit--items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
}

.error{ border-bottom: solid 1px #e02222 !important; }
label.error{ display: none !important; }
label[for="recorreo"]{ display: block !important; border: none !important; }

.post-offer {
  .container {
    padding-top: 4rem;
  }
  .title {
    color: rgb(28, 88, 130);
    font-family: "Poppins Semibold";
    font-size: 20px;
    line-height: 20px;
    margin: 0rem;
    text-transform: uppercase;
  }
  .container.benefits {
    padding: 4em 0;
  }
  .container-beneficios {
    padding:0;
  }
  .card-panel {
    border: 1px solid rgb(28, 88, 130);
    border-radius: 4px;
    box-shadow: none;
    &:hover {
      border: 1px solid rgb(228, 34, 74);
      box-shadow: 0px 1px 8px 0px #b5b5b5;
      cursor: pointer;
    }
    .card-title {
      font-family: "Poppins Medium";
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      min-height: 44px;
      color: rgb(228, 34, 74);
      transition: all 300ms;
      max-height: 44px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .card-item {
      font-family: "Poppins";
      font-size: 20px;
      line-height: 22px;
      color: rgb(38, 38, 38);
      margin-bottom: 1rem;
    }

    .small {
      font-family: "Poppins";
      font-size: 12px;
      line-height: 12px;
      color: rgb(38, 38, 38);
      margin-bottom: 1rem;
      text-transform: uppercase;
    }
  }
  .form {
    .form-control {
      border: 1px solid rgb(128, 128, 128);
      border-radius: 5px;
      width: 100%;
      padding: 0.8em;
    }
    .share {
      padding: 1em 0;
    }
    label {
      font-weight: bold;
    }
    span {
      color: rgb(28, 88, 130);
    }
  }
  .btn-azul {
    display: inline-block;
    width: auto;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 13px;
    color: rgb(255, 255, 255);
    padding: 12px 28px;
    padding-top: 13px;
    border: 0px;
    border-radius: 20px;
    background-color: rgb(28, 88, 130);
    cursor: pointer;
    outline: none;
    transition: all 300ms;
    text-transform: uppercase;
  }
}

@media #{$small-and-down} {
  .dos-abajo {
    padding-bottom: 32px !important;
  }
}

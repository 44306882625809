.form_modal {
  h5 {
    color: #1c5882;
    font-weight: 500;
    margin-top: 3%;
  }
  p {
    margin: 0px;
    text-align: left;
  }
  .title {
    color: #1c5882 !important;
    font-weight: bold !important;
  }
  hr {
    margin: 1px;
  }
}
span.status {
  display: inline-block;
  width: 100px;
  padding: 0.3em 0.6em;
  color: white;
  text-align: center;
}
.label-success {
  background-color: #5cb85c;
}
.label-warning {
  background-color: #f0ad4e;
}
.label-default {
  background-color: #777;
}
.label-danger {
  background-color: #c95656;
}

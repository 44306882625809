//*-------------------------------------------------------------------------------------*//
//* Mis ofertas
//*-------------------------------------------------------------------------------------*//
.noresize{
  resize: none !important;
}
.recomend{
  .card-panel{
    padding: 10px;
    span{
      font-size: 95px;
      color: $azul;
    }
    h6{
      margin-bottom: -30px;
    }
    h4{
      margin-top: 0px;
    }
  }
}
.form-ofertas-div {
  width: 100%;
  form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    input {
      font-family: $poppins-light;
      font-size: 14px;
      padding: 12px 12px;
      border: 1px solid #dcdcdc;
      border-radius: 3px;
      background-color: white;
      outline: none;
      width: 40%;
      margin-right: 0px;
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #333333 !important;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: #333333 !important;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: #333333 !important;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: #333333 !important;
      }
    }
    button {
      width: auto;
      font-family: $poppins;
      font-size: 12px;
      line-height: 12px;
      color: white;
      padding: 16px 20px;
      padding-top: 17px;
      margin: 0px 10px;
      background-color: $rojo;
      border-radius: 4px;
      text-align: center;
      display: block;
      transition: all 300ms;
      &:hover {
        color: white;
        background-color: #bb0d31;
      }
    }
  }
}

.ofertas-cont {
  margin-top: 4rem;
  position: relative;
  margin-top: 4rem;
  .rayo {
    border-radius: 2px 0 0 0;
  }
}
.mis-vacantes{
  padding-top: 2rem;
}
.oferta {
  padding: 2rem;
  padding-bottom: 1rem;
  border-radius: 4px;
  margin-bottom: 12px;
  background-color: #f2f2f2;
  position: relative;
  @media #{$large-and-up} {
    .row {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  .img-fluid {
    max-width: 100%;
    margin: auto;
  }

  .img-oferta {
    margin: auto;
  }

  .desc-oferta {
    /*width: 60%;*/
    padding: 1rem;
    h2 {
      font-family: $poppins-semibold;
      font-size: 20px;
      line-height: 22px;
      color: $azul;
      margin: 0rem;
      padding-bottom: 0rem;
      margin-bottom: 1rem;
    }
    .desc {
      font-family: $poppins-light;
      font-size: 14px;
      line-height: 20px;
      color: $negro;
      margin: 0rem;
      margin-bottom: 1rem;
      text-transform: initial;
      max-height: 10em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .caracterisitcas {
      display: flex;
      flex-wrap: wrap;
      .car {
        width: 33%;
        margin-bottom: 14px;
        p {
          font-family: $poppins-light;
          font-size: 14px;
          line-height: 14px;
          color: $negro;
          margin: 0rem;
          margin-bottom: 6px;
          &:first-of-type {
            font-family: $poppins-medium;
          }
        }
      }
    }
  }
  .btn-ofertas {
    // a {
    // 	width: auto;
    //           font-family: $poppins;
    //           font-size: 12px;
    //           line-height: 12px;
    //           color: $negro;
    //           padding: 16px 20px;
    //           padding-top: 17px;
    //           margin: 0px 10px;
    //           background-color: #e7e7e7;
    //           border: 0px;
    //           border-radius: 4px;
    //           text-align: center;
    //           display: block;
    //           transition: all 300ms;
    //           &:hover {
    //               color: white;
    //               background-color: $rojo;
    //           }
    // }
    a,
    button {
      min-width: 110px;
      font-family: $poppins;
      font-size: 12px;
      line-height: 12px;
      color: $negro;
      padding: 16px 20px;
      padding-top: 17px;
      margin: 0px 10px;
      margin-bottom: 4px;
      background-color: #e7e7e7;
      border: 0px;
      border-radius: 4px;
      text-align: center;
      display: block;
      outline: none;
      cursor: pointer;
      transition: all 300ms;
      &:hover {
        color: white;
        background-color: $rojo;
      }
    }
  }
}

.paginacion-div {
  width: 100%;
  text-align: center;
  .pagination li.active {
    background-color: $azul;
    cursor: pointer;
    transition: all 300ms;
    &:hover {
      background-color: #408ec5;
    }
  }
}

.imagenes-modal {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .item_imagen {
    width: 23%;
    margin: 1%;
    position: relative;
    cursor: pointer;
    .layer-azul {
      background-color: rgba($azul, 0.6);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: all 300ms;
      i {
        color: white;
        font-size: 30px;
      }
    }
    img {
      width: 100%;
      vertical-align: middle;
    }
    &:hover {
      .layer-azul {
        opacity: 1;
      }
    }
  }
  .active_ima {
    .layer-azul {
      opacity: 1;
    }
  }
}
.consejos{
  p{
    font-size: 12px;
  }
  ul li{
    font-size: 12px;
    list-style-type: circle;
    margin-left: 20px;
  }
}
//*-------------------------------------------------------------------------------------*//
//* Comprar creditos
//*-------------------------------------------------------------------------------------*//
.pad-cuenta {
  padding-top: 4rem;
  padding-bottom: 2rem;
}
.sub-general {
  padding: 2rem 0rem;
  text-align: center;
  p {
    font-family: $poppins-medium;
    font-size: 18px;
    line-height: 18px;
    color: $azul;
    margin: 0px;
  }
}
.pasos-creditos {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 3rem;
  .paso {
    padding: 0 1rem;
    span {
      width: 80px;
      height: 80px;
      border-radius: 150px;
      display: block;
      margin: 0 auto;
      background-color: #e7e7e7;
      display: flex;
      font-family: $poppins-medium;
      font-size: 28px;
      line-height: 26px;
      color: $azul;
      align-items: center;
      justify-content: center;
      transition: all 300ms;
    }
    p {
      font-family: $poppins-medium;
      font-size: 16px;
      line-height: 16px;
      color: $azul;
      margin: 0rem;
      padding-top: 8px;
      text-align: center;
    }
  }
  .active {
    span {
      background-color: $azul;
      color: white;
    }
  }
}
.planes-cont {
  h5 {
    font-weight: bold;
    color: rgb(38, 38, 38);
  }
  // styles for medium screens and down
  li {
    color: rgb(38, 38, 38);
    margin: 0.25em !important;
    font-size: 16px !important;
  }
  th{
    color: $rojo;
    font-size: 16px;
    font-weight: bold;
  }
  tr {
    cursor: pointer;
  }
  td:nth-child(2n){
    max-width: 120px;
  }
  .iazul{
    color: $azul;
    font-size: 18px;
    font-weight: bold;
  }
  td b{
    color: $azul;
    font-size: 25px;
    font-weight: bold;
  }
  td strong{
    font-size: 16px;
    font-weight: bold;
  }
  .estetico{

    b{
        color: $rojo;
    }
  }
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2rem;
  .plan-div {
    width: 23%;
    margin: 1%;
    border: 1px solid $azul;
    padding: 2rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    transition: all 300ms;
    @media (max-width: 1300px) {
      width: 31%;
    }
    @media (max-width: 993px) {
      width: 48%;
    }
    .radio-div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;
    }
    h2 {
      font-family: $poppins;
      font-size: 16px;
      line-height: 18px;
      color: $azul;
      margin: 0;
      padding-left: 6px;
      text-align: center;
    }
    p {
      margin: 0rem;
      width: 100%;
    }

    .creditos {
      font-family: $poppins;
      font-size: 20px;
      line-height: 22px;
      color: $negro;
      margin-bottom: 1rem;
    }
    .destacados {
      font-family: $poppins;
      font-size: 12px;
      line-height: 12px;
      color: $negro;
      margin-bottom: 1rem;
      text-transform: uppercase;
    }
    .dias {
      font-family: $poppins;
      font-size: 12px;
      line-height: 12px;
      color: $rojo;
      margin-bottom: 1rem;
      text-transform: uppercase;
    }
    .precio {
      font-family: $poppins-medium;
      font-size: 22px;
      line-height: 22px;
      color: $azul;
      transition: all 300ms;
      @media (max-width: 993px) {
        font-size: 18px;
        line-height: 18px;
      }
    }
    &:hover {
      border: 1px solid $rojo;
      box-shadow: 0px 1px 8px 0px #b5b5b5;
      .precio {
        color: $rojo;
      }
    }
  }
  .active {
    border: 1px solid $rojo;
    box-shadow: 0px 1px 8px 0px #b5b5b5;
    .precio {
      color: $rojo;
    }
  }
}
.pagos-cont {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 1rem;
  .pago-div {
    display: flex;
    align-items: center;
    padding: 20px;
    margin: 0px 12px;
    cursor: pointer;
    border: 1px solid #f2f2f2;
    background-color: #f2f2f2;
    border-radius: 3px;
    transition: all 300ms;
    img {
      width: 100px;
    }
    p {
      font-family: $poppins-medium;
      font-size: 16px;
      line-height: 16px;
      color: $negro;
      margin-left: 8px;
    }
  }
  .active {
    border-color: $rojo;
    background-color: white;
  }
}
.pad-pago {
  padding-top: 6rem;
}
.pago-texto-cont {
  width: 100%;
  display: flex;
  justify-content: center;
  .pago-texto {
    width: 80%;
    padding: 4rem;
    border: 2px solid #e6e6e6;
    background-color: white;
    text-align: center;
    p {
      font-family: $poppins;
      font-size: 18px;
      line-height: 24px;
      color: #1c5882;
      span {
        font-size: 24px;
        line-height: 24px;
        color: $azul;
        display: block;
        text-transform: uppercase;
        margin-bottom: 1rem;
      }
    }
  }
}

tr.selected,
tr.selected:hover {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(228, 34, 74) !important;
  td {
    border-radius: 0 !important;
    color: white !important;
  }
  b{
    color: white !important;
  }
  h5 {
    color: white !important;
  }

  li {
    color: white !important;
  }
}

.tab-contenedor {
    display: none;
}
.cont-active {
    display: block;
}
.ocultar-tab {
    ms-transform: translateY(100%); /* IE 9 */
    -webkit-transform: translateY(100%); /* Chrome, Safari, Opera */
    transform: translateY(100%);
    transition: all 300ms;
}
.mostrar-tab {
    ms-transform: translateY(0%); /* IE 9 */
    -webkit-transform: translateY(0%); /* Chrome, Safari, Opera */
    transform: translateY(0%);
    transition: all 300ms;
}
.tab-cont-custom {
    padding: 1rem 2rem;
    padding-bottom: 4rem;
    background-color: #f4f4f4;
    @media (max-width: 769px) {
        padding: 0rem;
        padding-bottom: 2rem;
    }
}
.tabs-custom {
    // margin-top: 1rem;
    // padding: 0rem 2rem;
    // padding-bottom: 2rem;
    // display: flex;
    // flex-wrap: wrap;
    // align-items: center;
    // justify-content: center;
    // @media (max-width: 769px) {
    //     padding-top: 1rem;
    // }
    .tb {
        // width: auto;
        // cursor: pointer;
        // position: relative;
        // overflow: hidden;
        // margin: 0rem;
        // background-color: white;
        // text-align: center;
        // padding: 14px 1rem;
        // transition: all 300ms;
        // @media (max-width: 650px) {
        //     width: 100%;
        //     margin: 0rem;
        //     margin-bottom: 1rem;
        // }
        // p {
        //     font-family: $roboto;
        //     font-size: 13px;
        //     line-height: 13px;
        //     color: $negro;
        //     margin: 0rem;
        //     text-align: center;
        //     text-transform: uppercase;
        //     transition: all 300ms;
        // }
        // img {
        //     width: 60px;
        // }
        // &:hover {
        //     background-color: $negro;
        //     p {
        //         color: white !important;
        //     }
        // }
    }
    .activo {
        // background: linear-gradient(to right, $azul-claro , $lila, $azul);
        background-color: $negro;
        p {
            color: white !important;
        }
    }
    .selector-tab {
        width: 100%;
        height: 8px;
        // background-color: #09522e;
        background-color: $azul;
        position: absolute;
        bottom: 0px;
    }
}
@import "materialize";

//VARIABLES
$screen-bg-min: 1500px;

$White: #fff;
$Black: #000;

$Facebook: #3b5998;
$Twitter: #4099ff;
$Instagram: #125688;
$Pinterest: #a9262a;
$Vimeo: #44bbff;
$Linkedin: #0077b5;
$Youtube: #e52d27;
$Red: #e52d27;

$Ocho: #888888;
$Gris: #292929;
$GrisFuerte: #4c4b4b;
$GrisClaro: #676767;
$Rosa: #fe1021;

$amarillo: #f5dd27;
$azul: #1c5882;
$azul-02: #337c77;
// $azul-02: #3aa39f;
$azul-claro: #3452ff;
$azul-obscuro: #001326;
// $negro: #001326;
$negro: #262626;
$naranja: #fe901f;
$rojo: #e4224a;
$gris-obscuro: #818181;
$gris-claro: #cccccc;
$gris-ea: #eaeaea;
$gris-cd: #cdcdcd;
$verde: #61b131;
$verde-obscuro: #43563b;

$primary: $Black;
$secundary: $Black;
$third: $Black;

$root: "../images/";

@import "mixes";
@import "helps";
@import "fonts";

$lato-thin: "Lato Thin";
$lato-light: "Lato Light";
$lato-light-italic: "Lato Light Italic";
$lato: "Lato";
$lato-bold: "Lato Bold";
$lato-black-italic: "Lato Black Italic";

$open-sans: "Open Sans";
$open-sans-light: "Open Sans Light";
$open-sans-light-italic: "Open Sans Light Italic";
$open-sans-bold: "Open Sans Bold";
$open-sans-extrabold: "Open Sans Extrabold";

$roboto-thin: "Roboto Thin";
$roboto-light: "Roboto Light";
$roboto: "Roboto";
$roboto-medium: "Roboto Medium";
$roboto-bold: "Roboto Bold";
$roboto-black: "Roboto Black";

$poppins-light: "Poppins Light";
$poppins: "Poppins";
$poppins-medium: "Poppins Medium";
$poppins-semibold: "Poppins Semibold";
$poppins-bold: "Poppins Bold";

$neucha: "Open Sans";

// Backgrounds ==================================================

.bg_black {
  background-color: $Black;
}
.bg_white {
  background-color: $White;
}

.facebook {
  background-color: $Facebook;
  color: $White;
}
.twitter {
  background-color: $Twitter;
  color: $White;
}
.instagram {
  background-color: $Instagram;
  color: $White;
}
.pinterest {
  background-color: $Pinterest;
  color: $White;
}
.vimeo {
  background-color: $Vimeo;
  color: $White;
}
.linkedin {
  background-color: $Linkedin;
  color: $White;
}

//*-------------------------------------------------------------------------------------*//
//*                                     Content
//*-------------------------------------------------------------------------------------*//
body {
  min-width: 320px !important;
  color: $Black;
  line-height: 1.4;
}

.visible-desktop {
  display: inherit;
}
.visible-tablet {
  display: none;
}
.visible-mobile {
  display: none;
}

.modal-xs {
  max-width: 600px;
}

.wow {
  visibility: hidden;
}

.lazyload,
.lazyloading {
  max-height: 900px;
  min-height: 425px;
  @media (max-width: 1400px) {
    max-height: 500px;
  }
}

.pace-running {
  .body-cont {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; /*for IE8 or IE9 only should come BEFORE filter*/
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
.pace-done {
  .body-cont {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; /*for IE8 or IE9 only should come BEFORE filter*/
    filter: alpha(opacity=100);
    opacity: 1;
    -webkit-transition: opacity 500ms linear;
    -moz-transition: opacity 500ms linear;
    -o-transition: opacity 500ms linear;
    transition: opacity 500ms linear;
  }
}

//*-------------------------------------------------------------------------------------*//
//*                                     Index
//*-------------------------------------------------------------------------------------*//
.row {
  margin-bottom: 0rem;
}
.width-100 {
  width: 100%;
}
.img-100 {
  width: 100%;
  vertical-align: middle;
}
.relative {
  position: relative;
}
.overflow {
  overflow: hidden;
}
.d-none {
  display: none;
}
.border-none {
  border: 0px !important;
}
.transparente {
  color: transparent !important;
}
.text-center {
  text-align: center;
}
.justify-align {
  text-align: justify;
}
.margin-auto {
  margin: 0 auto;
}
.uno-arriba {
  padding-top: 16px;
}
.dos-arriba {
  padding-top: 32px;
}
.tres-arriba {
  padding-top: 48px !important;
}
.cuatro-arriba {
  padding-top: 64px;
}
.uno-abajo {
  padding-bottom: 16px;
}
.dos-abajo {
  padding-bottom: 32px;
}
.tres-abajo {
  padding-bottom: 48px;
}
.cuatro-abajo {
  padding-bottom: 64px;
}
.m-uno-arriba {
  margin-top: 1rem;
}
.m-dos-arriba {
  margin-top: 2rem;
}
.m-tres-arriba {
  margin-top: 3rem;
}
.m-cuatro-arriba {
  margin-top: 4rem;
}
.m-uno-abajo {
  margin-bottom: 1rem;
}
.m-dos-abajo {
  margin-bottom: 2rem;
}
.m-tres-abajo {
  margin-bottom: 3rem;
}
.m-cuatro-abajo {
  margin-bottom: 4rem;
}
.block {
  display: block !important;
}

.owl-carousel {
  .owl-nav {
    position: absolute !important;
    top: calc(50% - 22px);
    left: 0% !important;
    padding: 0 0.9375rem;
    width: 100% !important;
    height: 1px;
    -ms-transform: translateY(-50%); /* IE 9 */
    -webkit-transform: translateY(-50%); /* Chrome, Safari, Opera */
    transform: translateY(-50%);
    img {
      opacity: 1;
    }
  }
  .owl-prev {
    width: auto;
    float: left;
    img {
      width: 46px;
      @media (max-width: 650px) {
        width: 34px;
      }
    }
  }
  .owl-next {
    width: auto;
    float: right;
    img {
      width: 46px;
      @media (max-width: 650px) {
        width: 34px;
      }
    }
  }
  // Styling dots
  .owl-nav.disabled + .owl-dots {
    margin-top: 10px;
  }

  .owl-dots {
    text-align: center;
    padding-top: 1rem;
    -webkit-tap-highlight-color: transparent;

    .owl-dot {
      display: inline-block;
      zoom: 1;
      *display: inline;

      span {
        width: 8px;
        height: 8px;
        margin: 8px;
        background: $negro;
        display: block;
        -webkit-backface-visibility: visible;
        transition: opacity 200ms ease;
        border-radius: 50px;
      }
      &.active,
      &:hover {
        span {
          background: $azul-claro;
        }
      }
    }
  }
}
.modal-link {
  padding: 1rem 2rem;
  text-align: center;
  border-radius: 10px;
  background-color: #f2f2f2;
  .cerrar-modal {
    width: 20px;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  h6 {
    font-family: $roboto-medium;
    font-size: 26px;
    line-height: 30px;
    color: $negro;
    text-align: center;
    // border-bottom: 1px solid $morado;
    padding-bottom: 1rem;
  }
  .separador-01 {
    // width: 110px;
    width: 100%;
    height: 1px;
    background-color: $azul;
    display: block;
    position: static;
    margin: 24px auto;
    margin-top: 0rem;
  }
  .texto-modal {
    p {
      font-family: $poppins;
      font-size: 16px;
      line-height: 20px;
      color: #333333;
      margin: 0rem;
      margin-bottom: 1rem;
    }
  }
  form {
    width: 100%;
    margin: 0 auto;
    padding-top: 1rem;
    @media (max-width: 650px) {
      width: 100%;
    }
    label {
      font-family: $roboto-light;
      font-size: 16px;
      line-height: 16px;
      color: $negro;
      display: block;
      margin-bottom: 8px;
    }
    input {
      border: 1px solid white;
      background-color: white;
      font-size: 14px;
      line-height: 14px;
      width: 100%;
      height: 45px;
      margin: 0rem 1%;
      margin-bottom: 1rem;
      padding: 0rem 12px;
      box-sizing: border-box !important;
      color: #777777;
      outline: none;
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #777777 !important;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: #777777 !important;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: #777777 !important;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: #777777 !important;
      }
    }

    .error {
      border: 1px solid $Red;
    }
  }
  .modal-footer {
    background-color: transparent;
  }
}
.pad-error {
  padding: 8rem 0rem;
}
.error-texto {
  padding-top: 3rem;
  text-align: center;
  p {
    width: 80%;
    border: 2px solid #e6e6e6;
    font-family: $poppins;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1px;
    color: #1c5882;
    margin: 0rem auto;
    padding: 6rem;
    // background-color: white;
    span {
      font-size: 24px;
      line-height: 24px;
      color: $azul;
      display: block;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }
  }
}
.pager {
  padding: 1rem 0rem;
  margin-top: 3rem;
  border-top: 1px solid $naranja;
  text-align: center;
  li {
    display: inline-block;
    padding: 1rem;
    a {
      color: $negro;
      transition: all 300ms;
      cursor: pointer;
      &:hover {
        color: $naranja;
      }
    }
  }
  .next,
  .previous {
    border: 0px !important;
  }
  .active {
    padding: 4px 10px;
    border: 1px solid $naranja;
  }
  .disabled {
    padding: 4px 10px;
    border: 1px solid #cccccc;
    a {
      color: $negro;
    }
    &:hover {
      border: 1px solid $naranja;
    }
  }
}

.anuncio-links a.active {
  color: white;
  background-color: #e4224a;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.validate_card {
  padding-top: 0px !important;
  margin-top: 0px !important;
}

.fondo-rosa {
  background-color: #bb0d31;
}

.validate_card {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.fondo-rojo {
  background-color: #e42222 !important;
}

.container_vacancy {
  text-align: left !important;
}

.container_vacancy p.title {
  color: #1c5882 !important;
  font-weight: bold !important;
}

.container_vacancy p span {
  color: #e4224a !important;
}

.container_vacancy p textarea {
  width: 100%;
}

input[type="checkbox"].error {
  box-shadow: 0 0px 3px #e02222 !important;
  -moz-box-shadow: 0 0px 3px #e02222 !important;
  -webkit-box-shadow: 0 0px 3px #e02222 !important;
}

.editExtras {
  right: 30px !important;
}

.editExtras a {
  color: #ffffff !important;
}

.check-form {
  height: 13px !important;
}

.delExtra,
.editExtras {
  cursor: pointer;
}

span.requerido {
  color: #e4224a;
}

.check-form {
  float: left;
  width: 16px !important;
  height: 16px !important;
  margin-top: 2px;
  margin-right: 10px;
}

@media print {
  .no-print {
    display: none;
  }
}

// @import "slider";
@import "async";
@import "header";
@import "home";
@import "nosotros";
@import "servicios";
@import "preguntas";
@import "contacto";
@import "vacante";
@import "tabscustom";
@import "registro";
@import "cuenta_empresa";
@import "footer";
@import "post-offer";
@import "perfil";
@import "pedidos";

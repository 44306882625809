.banner-pagina {
  position: relative;
  img {
    width: 100%;
    vertical-align: middle;
  }
}
.layer-negro {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(black, 0.6);
  p {
    font-family: $roboto-bold;
    font-size: 32px;
    line-height: 34px;
    color: white;
    margin: 0rem;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%); /* IE 9 */
    -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
  }
}
.back-f2 {
  background-color: #f2f2f2;
}
.form-general {
  width: 100%;
  padding: 0rem;
  padding-bottom: 4rem;
  .sub-form {
    width: 100%;
    padding: 2rem 0rem;
    p {
      font-family: $poppins-medium;
      font-size: 18px;
      line-height: 18px;
      color: $negro;
      margin: 0rem;
      text-transform: uppercase;
    }
  }
  .sub-term {
    p {
      font-family: $poppins-semibold;
      font-size: 20px;
      line-height: 20px;
      color: $azul;
      margin: 0rem;
      text-transform: uppercase;
    }
  }
  .campos-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-group {
      width: 49%;
      height: auto;
      margin-bottom: 7px;
    }
    .form-group-full {
      width: 100%;
    }
    label {
      display: block;
      font-size: 14px;
      margin-bottom: 4px;
      span {
        color: $rojo;
      }
    }
    input {
      font-family: $poppins-light;
      font-size: 14px;
      padding: 12px 12px;
      border: 1px solid #dcdcdc;
      border-radius: 3px;
      background-color: white;
      outline: none;
      width: 100%;
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #333333 !important;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: #333333 !important;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: #333333 !important;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: #333333 !important;
      }
    }
    .btn-azul{
      margin-top: 15px;
    }
    select {
      font-family: $poppins-light;
      font-size: 14px;
      padding: 12px 12px;
      padding-right: 36px;
      border: 1px solid #dcdcdc;
      border-radius: 3px;
      width: 100%;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%),
        linear-gradient(to right, #ccc, #ccc);
      background-position: calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
      background-size: 5px 5px, 5px 5px, 1px 1.5em;
      background-repeat: no-repeat;
      span {
        color: red;
      }
    }
    textarea {
      width: 100%;
      height: 150px;
      border: 1px solid #dcdcdc;
      background-color: white;
      padding: 14px 12px;
      font-family: $poppins-light;
      font-size: 14px;
      line-height: 14px;
      margin: 0rem;
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #333333 !important;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: #333333 !important;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: #333333 !important;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: #333333 !important;
      }
    }
    select.valid,
    input[type="password"].valid,
    textarea.valid {
      box-shadow: 0 1px 0 0 #4caf50;
      background-color: #e8f0fe;
    }
    small {
      display: block;
    }
  }
  .div-botones {
    width: 98%;
    margin: 0 auto;
    overflow: hidden;
    padding-top: 2rem;
    p {
      width: auto;
      font-family: $poppins;
      font-size: 12px;
      line-height: 12px;
      color: white;
      margin: 0rem;
      padding: 12px 28px;
      padding-top: 13px;
      border-radius: 20px;
      cursor: pointer;
      transition: all 300ms;
      text-transform: uppercase;
    }
    .btn-anterior {
      float: left;
      background-color: #333333;
      &:hover {
        background-color: #5c5c5c;
      }
    }
    .btn-siguiente {
      float: right;
      margin-left: 10px;
      background-color: $azul;
      &:hover {
        background-color: #136cab;
      }
    }
  }
}

.pad-contact {
  padding-top: 1rem;
  padding-bottom: 4rem;
}

.contact-form {
  width: 100%;
  margin: 0 auto;
  padding: 1rem 0rem;
  @media (max-width: 650px) {
    width: 100%;
  }
  label {
    font-family: $roboto-light;
    font-size: 16px;
    line-height: 16px;
    color: $negro;
    display: block;
    margin-bottom: 8px;
  }
  .input-home {
    border: 1px solid white;
    background-color: white;
    font-size: 14px;
    line-height: 14px;
    float: left;
    width: 48%;
    height: 45px;
    margin: 0rem 1%;
    margin-bottom: 1rem;
    padding: 0rem 12px;
    box-sizing: border-box !important;
    color: #777777;
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #777777 !important;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #777777 !important;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #777777 !important;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: #777777 !important;
    }
  }

  textarea {
    border: 1px solid white;
    background-color: white;
    padding: 12px;
    width: 98%;
    height: 200px;
    box-sizing: border-box !important;
    margin: 0rem 1%;
    margin-bottom: 1rem;
    color: #777777;
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #777777 !important;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #777777 !important;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #777777 !important;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: #777777 !important;
    }
  }
  .error {
    border: 1px solid $Red;
  }
}
.row-form {
  width: 96%;
  margin: 0 auto;
  @media (max-width: 650px) {
    width: 100% !important;
  }
}
.info-contacto {
  padding: 2rem;
  margin-top: 3rem;
  background-color: white;
  border: 1px solid #dcdcdc;
  @media (max-width: 769px) {
    margin-top: 0rem;
  }
  .info-div {
    width: 100%;
    position: relative;
    border-bottom: 1px solid #f2f2f2;
    margin-bottom: 1.4rem;
    &:last-of-type {
      border: 0px;
    }
    p {
      font-family: $poppins;
      font-size: 14px;
      line-height: 20px;
      color: $negro;
      margin: 0rem;
      padding-bottom: 4px;
      position: relative;
      &:first-of-type {
        font-family: $poppins-medium;
        padding-bottom: 4px;
      }
      &:last-of-type {
        padding-bottom: 18px;
      }
      a {
        font-family: $poppins;
        font-size: 16px;
        line-height: 20px;
        color: $negro;
        &:hover {
          color: $azul;
        }
      }
    }
    .sub-contacto {
      font-size: 18px;
      line-height: 18px;
      color: $azul;
      margin-bottom: 10px;
      padding-bottom: 4px !important;
    }
    .redes-contacto {
      width: 100%;
      display: inline-block;
      padding-top: 10px;
      a {
        margin: 0rem 16px;
        font-size: 26px;
        line-height: 26px;
        transition: all 300ms;
        color: $negro;
        &:hover {
          color: $rojo;
        }
      }
    }
  }
}
.hours-info {
  span {
    padding-left: 2rem;
    float: right;
  }
}
.mapa-contenedor {
  width: 100%;
}
#mapa-div {
  border-top: 2px solid $rojo;
  width: 100%;
  height: 400px;
}

////// ********||  Home Principal  ||******** //////
    .show_laptop{ display: inherit !important; }
    .show_mobile{ display: none !important; }
    .show_tablet{ display: none !important; }


    @media #{$small-and-down} {
        .show_mobile{ display: inherit !important; }
        .show_tablet{ display: none !important; }
        .show_laptop{ display: none !important; }
        .banner-div {
            padding-top: 4em!important;
            max-height: 250px;
            overflow: hidden;
        }

    }

    @media #{$medium-and-up} {
        .show_mobile{ display: none !important; }
        .show_tablet{ display: none !important; }
        .show_laptop{ display: inherit !important; }
    }

    @media #{$large-and-up} {
        .show_mobile{ display: none !important; }
        .show_tablet{ display: none !important; }
        .show_laptop{ display: inherit !important; }
    }

    /*@media (max-width: 769px) {
        .show_mobile{ display: none !important; }
        .show_tablet{ display: inherit !important; }
        .show_laptop{ display: none !important; }
    }

    @media (max-width: 550px) {
        .show_mobile{ display: inherit !important; }
        .show_tablet{ display: none !important; }
        .show_laptop{ display: none !important; }
    }*/

    .layer-azul {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background-color: rgba(#003c77,0.7);
    }
    .owl-home {
        .info-slider {
            width: 60%;
            position: absolute;
            top: 50%;
            left: 20%;
            -ms-transform: translateY(-50%); /* IE 9 */
            -webkit-transform: translateY(-50%); /* Chrome, Safari, Opera */
            transform: translateY(-50%);
            @media (max-width: 993px) {
                width: 80%;
                left: 50%;
                text-align: center;
                -ms-transform: translate(-50%, -50%); /* IE 9 */
                -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
                transform: translate(-50%, -50%);
            }
            h1 {
                font-family: $poppins-bold;
                font-size: 50px;
                line-height: 50px;
                color: white;
                margin: 0rem;
                padding: 0rem;
                padding-bottom: 1rem;
                @media (max-width: 993px) {
                    font-size: 30px;
                    line-height: 30px;
                }
            }
            p {
                font-family: $poppins-semibold;
                font-size: 22px;
                line-height: 28px;
                color: #d8d8d8;
                margin: 0rem;
                padding: 0rem;
                padding-bottom: 1.6rem;
            }
            a {
                display: inline-block;
                width: auto;
                font-family: $poppins;
                font-size: 12px;
                line-height: 13px;
                color: white;
                padding: 12px 20px;
                padding-top: 13px;
                border-radius: 20px;
                background-color: $azul;
                text-transform: initial;
                transition: all 300ms;
                &:hover {
                    background-color: #136cab;
                }
                svg {
                    margin-left: 6px;
                }
            }
        }
        .owl-nav {
            button {
                outline: none;
            }
            .owl-prev {
                img {
                    width: 22px;
                }
                @media (max-width: 769px) {
                    position: relative;
                    left: -1%;
                }
            }
            .owl-next {
                img {
                    width: 22px;
                }
                @media (max-width: 769px) {
                    position: relative;
                    right: -1%;
                }
            }
        }
    }
    .owl-cont {
        position: relative;
    }
    .buscador-cont {
        width: 60%;
        overflow: hidden;
        position: absolute;
        bottom: 0px;
        left: 50%;
        z-index: 15;
        transform: translate(-50%,50%);
        -ms-transform: translate(-50%,50%); /* IE 9 */
        -webkit-transform: translate(-50%,50%); /* Chrome, Safari, Opera */
        @media (max-width: 993px) {
            width: 70%;
            /*position: relative;*/
            box-shadow: -3px 4px 8px -2px #888888;
            transform: translate(-50%,-20%);
            -ms-transform: translate(-50%,-20%); /* IE 9 */
            -webkit-transform: translate(-50%,-20%); /* Chrome, Safari, Opera */
        }
        @media (max-width: 993px) {
            width: 90%;
        }
        p {
            font-family: $poppins-medium;
            font-size: 14px;
            line-height: 14px;
            color: $negro;
            margin: 0px;
            margin-bottom: -1px;
            padding: 1.4rem 2rem;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            border-top: 2px solid $rojo;
            background-color: white;
            display: inline-block;
            text-transform: uppercase;
        }
        form {
            padding: 2.4rem 2rem;
            padding-bottom: 18px;
            background-color: white;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            @media (max-width: 993px) {
                justify-content: space-between;
            }
            .campos-home {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                height: 49px;
                transition: height 300ms;
                overflow: hidden;
            }
            input {
                width: 24%;
                height: auto;
                padding: 14px 16px;
                padding-top: 15px;
                margin: 0px 0.25%;
                margin-bottom: 0.5%;
                font-family: $poppins;
                font-size: 14px;
                line-height: 20px;
                color: $negro;
                background-color: #e7e7e7;
                border: 0px;
                outline: none;
                &:first-of-type {
                    margin-left: 0px;
                }
                @media (max-width: 993px) {
                    width: 100%;
                    margin-bottom: 4%;
                }
            }
            .search-input{
              @media #{$medium-and-down} {
                  width: 70%;
              }
            }
            .input-submit {
                font-family: $poppins-medium;
                font-size: 13px;
                color: white;
                background-color: $azul;
                cursor: pointer;
                text-transform: uppercase;
                transition: all 300ms;
                @media #{$medium-and-down} {
                    width: 29%;
                    margin-bottom: 4%;
                }
                &:hover {
                    background-color: #136cab;
                }
            }
            select {
                width: 24%;
                height: 49px;
                margin: 0px 0.25%;
                margin-bottom: 0.5%;
                padding: 0px 16px;
                font-family: $poppins;
                font-size: 14px;
                line-height: 20px;
                color: $negro;
                border: 0px;
                background-color: #e7e7e7;
                @media (max-width: 993px) {
                    width: 100%;
                    height: 49px;
                    margin-bottom: 4%;
                }
            }
            .avanzada {
                width: 100%;
                padding-top: 1rem;
                text-align: center;
                p {
                    font-family: $poppins-semibold;
                    font-size: 11px;
                    line-height: 11px;
                    color: $azul;
                    border: 0px;
                    margin: 0rem;
                    padding: 0rem;
                    cursor: pointer;
                    i {
                        vertical-align: middle;
                        margin-top: -4px;
                        margin-right: 4px;
                        line-height: 12px;
                    }
                }
            }
        }
    }
    .empleos-home-cont {
        .tab-contenedor {
            display: none !important;
        }
        .cont-active {
            display: flex !important;
        }
        .tabs-custom {
            .activo {
                background-color: transparent;
                p {
                    color: $rojo !important;
                }
            }
        }
    }
    .height-auto {
        height: 105px !important;
    }

////// ********||  Empleos Home  ||******** //////
    .sub-home {
        text-align: center;
        p {
            font-family: $poppins-semibold;
            font-size: 26px;
            line-height: 32px;
            color: $negro;
            margin: 0rem;
            padding: 0rem;
            padding-bottom: 8px;
            &:last-of-type {
                font-family: $poppins;
                font-size: 16px;
                line-height: 16px;
                margin: 0rem;
                padding: 0rem;
            }
        }
    }
    .tipo-vista-cont {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 2rem 1rem;
        padding-top: 3rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid #cccccc;
        .tipo {
            display: flex;
            align-items: center;
            margin-right: 1rem;
            padding: 6px 10px;
            img {
                width: 16px;
                margin-right: 10px;
                vertical-align: middle;
            }
            p {
                font-family: $poppins;
                font-size: 14px;
                line-height: 14px;
                color: black;
                margin: 0rem;
                cursor: pointer;
            }
        }
    }
    .empleos-home-cont {
        width: 100%;
        padding-top: 9rem;
        padding-bottom: 4rem;
        background-color: #e5e4e5;
        @media #{$medium-and-down} {
            padding-top: 2rem;
        }
        .empleos-home {
            display: flex;
            flex-wrap: wrap;
            .empleo {
                width: 30%;
                margin: 1.6%;
                border-radius: 10px;
                background-color: white;
                overflow: hidden;
                position: relative;
                transition: all 300ms;
                @media (max-width: 993px) {
                    width: 100%;
                    margin: 0rem;
                    margin-bottom: 2rem;
                }
                img {
                    width: 100%;
                    vertical-align: middle;
                }
                .rayo {
                    position: absolute;
                    top: -1px;
                    left: 0px;
                    width: 60px;
                }
                .info {
                    padding: 1.6rem 2rem;
                }
                p {
                    font-family: $poppins;
                    font-size: 12px;
                    line-height: 12px;
                    color: $rojo;
                    margin: 0rem;
                    padding: 0rem;
                    margin-bottom: 6px;
                }
                h1 {
                    font-family: $poppins-semibold;
                    font-size: 18px;
                    line-height: 20px;
                    color: black;
                    margin: 0rem;
                    padding: 0rem;
                    margin-bottom: 12px;
                    text-transform: uppercase;
                }
                .date {
                    color: #868686;
                }
                .place {
                    color: black;
                }
                .jornada {
                    width: auto;
                    display: inline-block;
                    font-family: $poppins-medium;
                    font-size: 10px;
                    line-height: 10px;
                    color: white;
                    margin: 0rem;
                    margin-top: 8px;
                    padding: 5px 10px;
                    padding-top: 6px;
                    border-radius: 4px;
                    text-transform: uppercase;
                }
                .j1 {
                    background-color: $verde;
                }
                .j2 {
                    background-color: $azul-claro;
                }
                &:hover {
                    box-shadow: 0px 4px 14px 3px #dcb4bc;
                }
            }
        }
        .empleos-home-list {
            display: flex;
            flex-wrap: wrap;
            .empleo {
                width: 100%;
                margin: 0px;
                position: relative;
                transition: all 300ms;
                border-bottom: 1px solid #cccccc;
                background-color: transparent;
                a {
                    display: block;
                    width: 100%;
                    display: flex;
                    align-items: center;
                }
                .info-img {
                    width: 20%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 46%;
                        @media (max-width: 1400px) {
                            width: 70%;
                        }
                        @media (max-width: 993px) {
                            width: 100%;
                        }
                    }
                }
                .rayo {
                    position: absolute;
                    top: -1px;
                    left: 0px;
                    width: 60px;
                }
                .info {
                    width: 80%;
                    padding: 2rem;
                    padding-top: 2.2rem;
                    @media (max-width: 993px) {
                        padding: 2rem 1rem;
                    }
                    div {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        &:first-of-type {
                            padding-bottom: 8px;
                            justify-content: space-between;
                        }
                    }

                    h1 {
                        font-family: $poppins-semibold;
                        font-size: 18px;
                        line-height: 20px;
                        color: black;
                        margin: 0rem;
                        padding: 0rem;
                        text-transform: uppercase;
                    }
                    p {
                        font-family: $poppins;
                        font-size: 12px;
                        line-height: 12px;
                        color: $rojo;
                        margin: 0rem;
                        padding: 0rem;
                        margin-right: 2rem;
                        margin-bottom: 6px;
                    }
                    .date {
                        color: #868686;
                    }
                    .place {
                        color: black;
                    }
                    .jornada {
                        width: auto;
                        display: inline-block;
                        font-family: $poppins-medium;
                        font-size: 10px;
                        line-height: 10px;
                        color: white;
                        margin: 0rem;
                        padding: 5px 10px;
                        padding-top: 6px;
                        border-radius: 4px;
                        text-transform: uppercase;
                    }
                    .j1 {
                        background-color: $verde;
                    }
                    .j2 {
                        background-color: $azul-claro;
                    }
                }
                &:hover {
                    background-color: white;
                    // box-shadow: 0px 4px 14px 3px #dcb4bc;
                }
            }
        }
        .boton-mas {
            width: 100%;
            text-align: center;
        }
    }
    .btn-azul {
        display: inline-block;
        width: auto;
        font-family: $poppins;
        font-size: 12px;
        line-height: 13px;
        color: white;
        padding: 12px 28px;
        padding-top: 13px;
        border: 0px;
        border-radius: 20px;
        background-color: $azul;
        cursor: pointer;
        outline: none;
        transition: all 300ms;
        text-transform: uppercase;
        &:hover {
            background-color: #136cab;
        }
    }
    .btn-azul-dos {
        display: inline-block;
        width: auto;
        font-family: $poppins;
        font-size: 14px;
        color: white;
        padding: 12px 28px;
        padding-top: 13px;
        border: 0px;
        border-radius: 20px;
        background-color: $rojo;
        cursor: pointer;
        outline: none;
        transition: all 300ms;
        text-transform: uppercase;
        box-shadow: 0 5px 15px rgba(0, 0, 0, .3);
        &:hover {
            background-color: #136cab;
        }
    }

    .btn-disabled {
        display: inline-block;
        width: auto;
        font-family: $poppins-medium;
        font-size: 12px;
        line-height: 13px;
        color: #a5a5a5;
        padding: 12px 28px;
        padding-top: 13px;
        border: 1px solid #dcdcdc;
        border-radius: 20px;
        background-color: #f2f2f2;
        transition: all 300ms;
        text-transform: uppercase;
        outline: none;
        cursor: initial !important;
    }
    .foto-home {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-top: 2rem;
        a {
            display: block;
            width: 50%;
            &:first-of-type {
                border-top-left-radius: 12px;
                border-bottom-left-radius: 12px;
                transition: all 300ms;
                &:hover {
                    box-shadow: -1px 2px 7px 2px #797979;
                }
            }
            &:last-of-type {
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
                transition: all 300ms;
                &:hover {
                    // box-shadow: -1px 2px 7px 2px #797979;
                    box-shadow: 1px 2px 7px 2px #797979;

                }
            }
        }

        @media #{$medium-and-down} {
            .campos-home{
                .search-input{
                    width: 70%;
                }
                .input-submit{
                    width: 29%;
                }
            }

            a {
                display: block;
                width: 100%;
                &:first-of-type {
                    border-top-left-radius: 12px;
                    border-bottom-left-radius: 12px;
                    border-top-right-radius: 12px;
                    border-bottom-right-radius: 12px;
                    transition: all 300ms;
                    overflow: hidden;
                    margin-bottom: 10px;
                    &:hover {
                        box-shadow: -1px 2px 7px 2px #797979;
                    }
                }
                &:last-of-type {
                    border-top-left-radius: 12px;
                    border-bottom-left-radius: 12px;
                    border-top-right-radius: 12px;
                    border-bottom-right-radius: 12px;
                    transition: all 300ms;
                    overflow: hidden;
                    &:hover {
                        // box-shadow: -1px 2px 7px 2px #797979;
                        box-shadow: 1px 2px 7px 2px #797979;

                    }
                }
            }
        }
        img {
            width: 100%;
            vertical-align: middle;
        }
    }


////// ********||  Servicios humanos home  ||******** //////
    .recursos-humanos-cont {
        padding: 4rem 0rem;
        padding-top: 4rem;
        .resursos-humanos {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            padding-top: 1rem;
            .recurso {
                width: 50%;
                display: flex;
                flex-wrap: wrap;
                padding: 2rem 1rem;
                @media (max-width: 993px) {
                    width: 100%;
                }
                .recur-img {
                    width: 30%;
                    text-align: center;
                    // padding: 1rem;
                    img {
                        width: 80%;
                        margin-bottom: 1rem;
                    }
                    p {
                        font-family: $poppins-medium;
                        font-size: 14px;
                        line-height: 16px;
                        color: #a09e9e;
                        margin: 0rem;
                    }
                }
                .recur-info {
                    width: 70%;
                    padding: 1rem;
                    h3 {
                        font-family: $poppins-semibold;
                        font-size: 22px;
                        line-height: 24px;
                        color: $azul;
                        margin: 0rem;
                        margin-bottom: 1.4rem;
                        text-transform: uppercase;
                    }
                    h5 {
                        font-family: $poppins;
                        font-size: 16px;
                        color:  #a09e9e;
                    }
                    p {
                        font-family: $poppins-medium;
                        font-size: 14px;
                        line-height: 16px;
                        color: $negro;
                        margin: 0rem;
                        padding: 4px 0px;
                    }

                }
                a {
                    display: inline-block;
                    width: auto;
                    font-family: $poppins;
                    font-size: 12px;
                    line-height: 13px;
                    color: white;
                    padding: 12px 20px;
                    padding-top: 13px;
                    border-radius: 20px;
                    background-color: $azul;
                    text-transform: initial;
                    transition: all 300ms;
                    margin-top: 1.6rem;
                    &:hover {
                        background-color: #136cab;
                    }
                }
            }
        }
    }


////// ********||  Login home  ||******** //////

    .login-home {
        width: 100%;
        padding: 3rem 0rem;
        background: rgb(29,88,130);
        background: -moz-linear-gradient(90deg, rgba(29,88,130,1) 0%, rgba(226,34,75,1) 100%);
        background: -webkit-linear-gradient(90deg, rgba(29,88,130,1) 0%, rgba(226,34,75,1) 100%);
        background: linear-gradient(90deg, rgba(29,88,130,1) 0%, rgba(226,34,75,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1d5882",endColorstr="#e2224b",GradientType=1);
    }
    .lh {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .sub-home {
            text-align: left;
            p {
                color: white;
            }
            margin-right: 4rem;
            @media (max-width: 993px) {
                width: 100%;
                margin-right: 0rem;
                margin-bottom: 2rem;
            }
        }
        a{
            display: block;
            width: auto;
            font-family: $poppins;
            font-size: 12px;
            line-height: 13px;
            color: white;
            padding: 12px 30px;
            padding-top: 13px;
            border-radius: 50px;
            border: 1px solid white;
            background-color: transparent;
            text-transform: initial;
            transition: all 300ms;
            img {
                width: 10px;
                margin-right: 6px;
                vertical-align: middle;
            }
            &:hover {
                background-color: $azul;
                border: 1px solid $azul;
            }
        }
    }


////// ********||  Canidatos Home ||******** //////
.owl-testimonials {
    width: 87%;
    margin: 0 auto;
    @media (max-width: 769px) {
        width: 82%;
    }
    .owl-nav {
        .owl-prev {
            position: relative;
            left: -10%;
            img {
                vertical-align: middle;
                width: 25px;
            }
            @media (max-width: 769px) {
                left: -18%;
            }
        }
        .owl-next {
            position: relative;
            right: -10%;
            img {
                vertical-align: middle;
                width: 25px;
            }
            @media (max-width: 769px) {
                right: -18%;
            }
        }
        button {
            outline: none;
        }
    }
    .owl-item {
        text-align: center;
        img {
            transition: all 300ms;
            width: 60%;
            margin: 0 auto;
            // -webkit-filter: grayscale(100%);  Safari 6.0 - 9.0
            // filter: grayscale(100%);
        }
        // &:hover {
        //     img {
        //         -webkit-filter: grayscale(0%);  Safari 6.0 - 9.0
        //         filter: grayscale(0%);
        //     }
        // }
    }
}
    .candidatos-home-cont {
        width: 100%;
        padding: 4rem 0rem;
        .candidatos-home {
            // display: flex;
            // flex-wrap: wrap;
            // justify-content: center;
            .candidato {

                margin: 1rem 1.6%;
                padding: 2rem;
                padding-top: 1rem;
                background-color: #e5e4e5;
                border-radius: 6px;
                transition: all 500ms;
                min-height: 340px;
                @media (max-width: 993px) {
                    width: 100%;

                }
                div {
                    width: 50px;
                    height: 50px;
                    border-radius: 100px;
                    background-size: cover;
                    background-position: 50%;
                    background-repeat: no-repeat;
                    margin-bottom: 1.4rem;
                    //margin-top: -2rem;
                }
                p {
                    font-family: $poppins;
                    font-size: 12px;
                    line-height: 17px;
                    color: #7c7a71;
                    margin-bottom: 2rem;
                }
                h4 {
                    font-family: $poppins-medium;
                    font-size: 16px;
                    line-height: 16px;
                    color: $negro;
                    margin: 0rem;
                    padding: 0rem;
                    transition: all 300ms;
                }
                .posicion {
                    margin-top: 8px;
                    margin-bottom: 0rem;
                }
                &:hover {
                    background-color: white;
                    box-shadow: 0px 4px 14px 3px #dcb4bc;
                    h4 {
                        color: $rojo;
                    }
                }
            }
        }
    }


////// ********||  Soluciones Empresariales||******** //////
    .soluciones-empresariales-cont {
        padding: 4rem 0rem;
        text-align: center;
        background-size: cover;
    }


////// ******** Marcas ******** //////
    .marcas-home {
        padding-bottom: 7rem;
    }
    .owl-marcas {
        width: 85%;
        margin: 0 auto;
        @media (max-width: 769px) {
            width: 84%;
        }
        .owl-nav {
            .owl-prev {
                position: relative;
                left: -10%;
                img {
                    vertical-align: middle;
                    width: 18px;
                }
                @media (max-width: 769px) {
                    left: -18%;
                }
            }
            .owl-next {
                position: relative;
                right: -10%;
                img {
                    vertical-align: middle;
                    width: 18px;
                }
                @media (max-width: 769px) {
                    right: -18%;
                }
            }
            button {
                outline: none;
            }
        }
        .owl-item {
            text-align: center;
            img {
                transition: all 300ms;
                width: 60%;
                margin: 0 auto;
                // -webkit-filter: grayscale(100%);  Safari 6.0 - 9.0
                // filter: grayscale(100%);
            }
            // &:hover {
            //     img {
            //         -webkit-filter: grayscale(0%);  Safari 6.0 - 9.0
            //         filter: grayscale(0%);
            //     }
            // }
        }
    }

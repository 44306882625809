// styles for small screens and down
@media #{$small-and-down} {
  .about-us {
    .nosotros-pad {
      text-align: center;
    }
    .tres-arriba {
      padding-top: 0 !important;
    }
    .icons {
      svg {
        margin-bottom: 0.5em;
      }
    }
  }
}

.empleos-pad {
  padding-top: 6rem;
  padding-bottom: 4rem;
  margin-bottom: 4rem;
}
.nosotros-pad {

  padding-bottom: 4rem;

}
.descripcion-nosotros {
  text-align: center;
  padding-bottom: 2rem;
  p {
    font-family: $poppins;
    font-size: 16px;
    line-height: 20px;
    color: $negro;
    padding: 0rem;
    margin: 0rem;
    margin-bottom: 1rem;
  }
}
.objetivos {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 3rem;
  .obj {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    img {
      width: 25%;
      vertical-align: middle;
    }
    .text {
      width: 75%;
      padding: 2rem;
      p {
        font-family: $poppins;
        font-size: 14px;
        line-height: 18px;
        color: $negro;
        padding: 0rem;
        margin: 0rem;
        margin-bottom: 1rem;
        &:first-of-type {
          font-family: $poppins-medium;
          font-size: 18px;
          line-height: 18px;
          color: $azul;
        }
      }
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }
}
.valores {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .icon-valor{
    width: 180px;
    height: 180px;
    border-radius: 180px;
    line-height: 220px;
    background: $azul;
    margin: auto;
    color: white;
    text-align: center;
    margin-bottom: 15px;
  }
  .valor {
    width: 30%;
    margin: 1.0%;
    padding: 0.5rem;
    text-align: center;
    .icon-us{
      color: white;
    }
    img {
      width: 50%;
      margin-bottom: 4rem;
      vertical-align: middle;
    }
    h4 {
      font-family: $poppins-semibold;
      font-size: 20px;
      line-height: 20px;
      color: $rojo;
      margin: 0rem;
      padding: 0rem;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    p {
      font-family: $poppins;
      font-size: 13px;
      line-height: 18px;
      color: $negro;
      margin: 0rem;
      padding: 0rem;
      text-align: center;
    }

    @media (max-width: 600px) {
      width: 100%;
    }

    @media (min-width: 601px) and (max-width: 991px) {
      width: 45%;
    }
  }
}

.icon-us {
  font-size: 70px;
  color: #e4224a;
}

.text-us p:first-of-type {
  font-family: "Poppins Medium";
  font-size: 18px;
  line-height: 18px;
  color: #1c5882;
}

.text-us p {
  font-family: "Poppins";
  font-size: 14px;
  line-height: 24px;
  color: #262626;
  padding: 0rem;
  margin: 0rem;
  margin-bottom: 1rem;
}

.text-us {
  padding-left: 10px;
}

.text-value h4 {
  font-family: "Poppins Semibold";
  font-size: 20px;
  line-height: 20px;
  color: #1c5882;
  margin: 0rem;
  padding: 0rem;
  margin-bottom: 14px;
  text-transform: uppercase;
}

.text-value {
  padding-left: 10px;
}

.text-value p {
  font-family: "Poppins";
  font-size: 14px;
  line-height: 24px;
  color: #262626;
  margin: 0rem;
  padding: 0rem;
  text-align: justify;
}

.padding-valor {
  margin-top: 15px;
  margin-bottom: 15px;
}

@media (min-width: 991px) and (max-width: 1200px) {
}

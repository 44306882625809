.sub-seccion {
    padding-top: 2rem;
    padding-bottom: 2rem;
    p {
        font-family: $poppins-medium;
        font-size: 30px;
        line-height: 34px;
        color: $azul;
        margin: 0rem;
        padding: 0rem;
        text-align: center;
    }
}
.preguntas-contenedor {
    padding: 4rem 0rem;
    ul {
        overflow: hidden;
        margin: 0rem;
        box-shadow: none;
        border: 0px;
        // display: flex;
        // flex-wrap: wrap;
        li {
            width: 100%;
            // float: left;
            padding: 0rem 8px;
            margin-bottom: 1rem;
            outline: none !important;
            &:nth-child(odd) {
                float: left;
            }
            &:nth-child(even) {
                float: right;
            }
            @media (max-width: 769px) {
                width: 100%;
            }
            .collapsible-header {
                color: white !important;
                padding: 10px 2rem;
                background-color: #f2f2f2;
                position: relative;
                outline: none !important;
                position: relative;
                p {
                    font-family: $poppins;
                    font-size: 16px;
                    line-height: 20px;
                    color: $negro;
                    margin: 8px 0rem;
                    padding: 0rem;
                    @media (max-width: 769px) {
                        line-height: 18px;
                    }
                }
                i {
                    width: auto;
                    color: $rojo;
                    font-size: 24px;
                    line-height: 24px;
                    margin-right: 10px;
                    position: absolute;
                    top: calc(50% - 12px);
                    right: 0px;
                }
                img {
                    width: 14px;
                    vertical-align: middle;
                    margin-right: 10px;
                }
            }
            .collapsible-body {
                padding: 1rem 1.5rem;
                background-color: white;
                border-bottom: 0px;
                p {
                    font-family: $poppins-light;
                    font-size: 16px;
                    line-height: 22px;
                    color: $negro;
                    margin: 0rem;
                    display: inline-block;
                }
                ul {
                    padding-left: 2rem;
                    margin-bottom: 1.5rem;
                    li {
                        font-size: 15px;
                        line-height: 22px;
                        padding-left: 10px;
                        border-left: 1px solid $azul;
                    }
                }
            }

        }
        .active {
            // border: 1px solid $azul-claro;
            .collapsible-header {
                background-color: $azul;
                p {
                    color: white;
                }
                i {
                    color: white;
                }
            }
        }
    }
}
//*-------------------------------------------------------------------------------------*//
//* Registro Pagina
//*-------------------------------------------------------------------------------------*//
.date_ok{
  display: none;
}
    .banner-div {
        width: 100%;
        position: relative;
        padding-top: 6em;
        img {
            width: 100%;
            vertical-align: middle;
            padding-top: 0em;
        }
        .banner-text {
            width: 50%;
            position: absolute;
            top: 60%;
            left: 50%;
            -ms-transform: translate(-50%, -50%); /* IE 9 */
            -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
            transform: translate(-50%, -50%);
            text-align: center;
            p {
                font-family: $poppins-semibold;
                font-size: 38px;
                line-height: 38px;
                color: white;
                margin: 0rem;
                padding: 0rem;
                text-shadow: 1px 1px 2px #333333;
                @media (max-width: 993px) {
                    font-size: 30px;
                    line-height: 30px;
                }
            }
        }
       @media (max-width: 1200px) {
            .show_laptop {
                padding-top: 4em!important;
            }
        }
    }

    @media #{$medium-and-up}
    {
        .banner-div {
            padding-top: 0!important;
        }
    }

    @media only screen and (min-width: 1261px) {
        .banner-div {
            padding-top: 6em!important;
        }
    }

    .file-field {
        .btn {
            height: 40px;
            line-height: 40px;
            background-color: $rojo;
        }
        .file-path-wrapper {
            input {
                font-family: $poppins-light;
                font-size: 14px;
                padding: 12px 12px;
                border: 1px solid #dcdcdc;
                border-radius: 3px;
                background-color: white;
                outline: none;
                width: 100%;
            }
        }
    }

    .modal-link {
        border-top: 2px solid $rojo;
        h6 {
            font-family: $poppins-semibold;
            color: $azul;
        }
        form {
            width: 90%;
            @media (max-width: 1400px) {
                width: 90%;
            }
            .form-group {
                width: 100%;
                margin-bottom: 1.2rem;
            }
            label {
                display: block;
                font-size: 14px;
                margin-bottom: 4px;
                text-align: left;
                color: black;
                span {
                    color: $rojo;
                }
            }
            input {
                font-family: $poppins-light;
                font-size: 14px;
                padding: 12px 12px;
                border: 1px solid #dcdcdc;
                border-radius: 3px;
                background-color: white;
                outline: none;
                width: 100%;
                margin: 0rem;
                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                  color: #333333 !important;
                }
                &::-moz-placeholder { /* Firefox 19+ */
                  color: #333333 !important;
                }
                &:-ms-input-placeholder { /* IE 10+ */
                  color: #333333 !important;
                }
                &:-moz-placeholder { /* Firefox 18- */
                  color: #333333 !important;
                }
            }
            .btn-azul {
                border: 0px;
                cursor: pointer;
            }
            .links-form {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                span {
                    margin: 0px 6px;
                }
                a {
                    font-family: $poppins-medium;
                    font-size: 12px;
                    line-height: 12px;
                    color: $negro;
                    transition: all 300ms;
                    &:hover {
                        color: $rojo;
                    }
                }
            }
        }
    }


//*-------------------------------------------------------------------------------------*//
//* Registro Aspirante
//*-------------------------------------------------------------------------------------*//
    .full-row {
        width: 100% !important;
    }
    .container_dates {
        .date_options {
            direction: flex;
            flex-wrap: wrap;
            .select_date_day {
                width: 24% !important;
            }
            .select_date_month {
                width: 49% !important;
            }
            .select_date_year {
                width: 24% !important;
            }
        }
    }
    .check-div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 1rem 0rem;
        label {
            margin: 0rem;
            margin-right: 4px;
        }
        input {
            width: 26px !important;
        }
    }
    .adding-div {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .formContainer {
            width: 50%;
            padding-bottom: 1rem;
            .form-group {
                width: 100% !important;
                margin-bottom: 18px !important;
            }
        }
        .infoContainer {
            width: 50%;
            padding: 1rem;
        }
    }
    .form-group {
        .btn-rojo {
            display: inline-block !important;
        }
    }
    .adding-result-cont {
        width: 100%;
        background-color: white;
        border: 1px solid #dcdcdc;
        border-radius: 3px;
        margin-bottom: 1.6rem;
        .titulo {
            font-family: $poppins;
            font-size: 12px;
            line-height: 12px;
            color: white;
            padding: 14px 24px;
            padding-top: 15px;
            background-color: $azul;
            position: relative;
            span {
                position: absolute;
                font-size: 14px;
                top: 13px;
                right: 10px;
            }
        }
        ul {
            width: 100%;
            margin: 0rem;
            padding: 16px 22px;
            li {
                width: 100%;
                font-family: $poppins-light;
                font-size: 14px;
                line-height: 16px;
                color: $negro;
                margin-bottom: 12px;
                span {
                    font-family: $poppins-medium;
                }
            }
        }
    }
    .lista-white {
        background-color: white;
        padding: 10px 16px;
        width: 100%;
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        span {
            color: $rojo;
        }
    }

//*-------------------------------------------------------------------------------------*//
//* Registro general
//*-------------------------------------------------------------------------------------*//
    .form-registro {
        width: 100%;
        padding: 2rem 0rem;
        padding-top: 4rem;
        .paso-div {
            display: none;
            padding: 3rem;
            border-radius: 16px;
            background-color: #f3f3f3;
            transition: 1s ease;
        }
        .paso-div.active{
            display: block;
        }
        .titulo-formulario {
            padding: 0rem;
            margin-bottom: 28px;
            position: relative;
            width: 100%;
            span {
                font-family: $poppins-medium;
                font-size: 14px;
                line-height: 14px;
                color: $rojo;
                display: block;
                margin-bottom: 6px;
            }
            p {
                font-family: $poppins-semibold;
                font-size: 20px;
                line-height: 20px;
                color: $azul;
                margin: 0rem;
                text-transform: uppercase;
            }
        }
        .sub-form {
            width: 100%;
            padding: 2rem 0rem;
            p {
                font-family: $poppins-medium;
                font-size: 18px;
                line-height: 18px;
                color: $negro;
                margin: 0rem;
                text-transform: uppercase;
            }
        }
        .campo-foto {
            width: 49%;
        }
        .campos-form {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .form-group {
                width: 49%;
                height: auto;
                margin-bottom: 2%;
            }
            label {
                display: block;
                font-size: 14px;
                margin-bottom: 4px;
                span {
                    color: $rojo;
                }
            }
            input {
                font-family: $poppins-light;
                font-size: 14px;
                padding: 12px 12px;
                border: 1px solid #dcdcdc;
                border-radius: 3px;
                background-color: white;
                outline: none;
                width: 100%;
                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                  color: #333333 !important;
                }
                &::-moz-placeholder { /* Firefox 19+ */
                  color: #333333 !important;
                }
                &:-ms-input-placeholder { /* IE 10+ */
                  color: #333333 !important;
                }
                &:-moz-placeholder { /* Firefox 18- */
                  color: #333333 !important;
                }
            }
            select {
                font-family: $poppins-light;
                font-size: 14px;
                padding: 12px 12px;
                padding-right: 36px;
                border: 1px solid #dcdcdc;
                border-radius: 3px;
                width: 100%;
                outline: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                background-image:
                    linear-gradient(45deg, transparent 50%, gray 50%),
                    linear-gradient(135deg, gray 50%, transparent 50%),
                    linear-gradient(to right, #ccc, #ccc);
                background-position:
                    calc(100% - 20px) calc(1em + 2px),
                    calc(100% - 15px) calc(1em + 2px),
                    calc(100% - 2.5em) 0.5em;
                background-size:
                    5px 5px,
                    5px 5px,
                    1px 1.5em;
                background-repeat: no-repeat;
                span {
                    color: red;
                }
            }
            textarea {
                width: 100%;
                height: 100px;
                border: 1px solid #dcdcdc;
                border-radius: 3px;
                background-color: white;
                padding: 12px 12px;
                font-family: $poppins-light;
                font-size: 14px;
                line-height: 14px;
                margin: 0rem;
                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                  color: #333333 !important;
                }
                &::-moz-placeholder { /* Firefox 19+ */
                  color: #333333 !important;
                }
                &:-ms-input-placeholder { /* IE 10+ */
                  color: #333333 !important;
                }
                &:-moz-placeholder { /* Firefox 18- */
                  color: #333333 !important;
                }
            }
            select.valid, input[type=password].valid, textarea.valid {
                box-shadow: 0 1px 0 0 #4caf50;
                background-color: #e8f0fe;
            }
            small {
                display: block;
            }
        }
        .div-botones {
            width: 98%;
            margin: 0 auto;
            overflow: hidden;
            padding-top: 2rem;
            p {
                width: auto;
                font-family: $poppins;
                font-size: 12px;
                line-height: 12px;
                color: white;
                margin: 0rem;
                padding: 12px 28px;
                padding-top: 13px;
                border-radius: 20px;
                cursor: pointer;
                transition: all 300ms;
                text-transform: uppercase;
            }
            .btn-anterior, .btn-anterior-empresa {
                float: left;
                background-color: #333333;
                &:hover {
                    background-color: #5c5c5c;
                }
            }
            .btn-siguiente {
                float: right;
                background-color: $azul;
                margin-left: 10px;
                &:hover {
                    background-color: #136cab;
                }
            }
        }
        .terminos {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            input {
                width: 16px !important;
                vertical-align: middle;
            }
            a {
                font-family: $poppins-medium;
                color: $negro;
                &:hover {
                    color: $rojo;
                }
            }
        }
        .sub-term {
            p {
                font-family: $poppins-semibold;
                font-size: 20px;
                line-height: 20px;
                color: $azul;
                margin: 0rem;
                text-transform: uppercase;
            }
        }
    }
    .btn-rojo {
        width: auto;
        font-family: $poppins;
        font-size: 12px;
        line-height: 12px;
        color: white;
        margin: 0rem;
        padding: 12px 28px;
        padding-top: 13px;
        border-radius: 20px;
        border: 0px;
        cursor: pointer;
        outline: none;
        transition: all 300ms;
        background-color: $rojo;
        text-transform: uppercase;
        &:hover {
            background-color: #bb0d31;
        }
    }


//*-------------------------------------------------------------------------------------*//
//* Editar Empresa
//*-------------------------------------------------------------------------------------*//
    .sub-cuenta {
        padding-top: 4rem;
        padding-bottom: 2rem;
        width: 100%;
        text-align: center;
        p {
            font-family: $poppins-medium;
            font-size: 34px;
            line-height: 36px;
            color: $azul;
            margin: 0rem;
            text-transform: uppercase;
        }
    }
    .anuncio-links {
        width: 65%;
        margin: 0 auto;
        padding: 2rem 1rem;
        padding-top: 2.2rem;
        border: 6px;
        border-radius: 10px;
        border-top: 2px solid $rojo;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        background-color: white;
        position: relative;
        margin-top: -4rem;
        box-shadow: 0px 1px 8px 0px #b5b5b5;
        @media #{$medium-and-down} {
            margin-top: -3rem;
        }
        @media (max-width: 770px) {
             margin-top: -10rem;
        }
        .row {
            width: 100%!important;
        }
        @media (max-width: 1400px) {
            width: 80%;
        }
        @media (max-width: 1300px) {
            width: 92%;
        }
        @media (max-width: 1080px) {
            width: 99%;
        }
        @media (max-width: 993px) {
            width: 90%;
        }
        a {
            width: auto;
            font-family: $poppins;
            font-size: 12px;
            line-height: 12px;
            // color: white;
            color: $negro;
            padding: 16px 20px;
            padding-top: 17px;
            margin: 0px 5px;
            // background-color: $rojo;
            background-color: #e7e7e7;
            border-radius: 4px;
            text-align: center;
            display: block;
            transition: all 300ms;
            @media #{$medium-and-down} {
                width: 48%;
                margin: 1%;
            }

            @media #{$large-and-up} {
                min-width: 22%;
                margin: auto;
                margin: 1%;
            }

            @media #{$extra-large-and-up} {
                min-width: 10%;
                margin: auto;
                margin: 0.5%;
            }
            @media only screen and (max-width: 1575px) {
                min-width: 22%;
                margin: auto;
                margin: 1%;
            }
            &:hover {
                color: white;
                background-color: $rojo;
                // background-color: #bb0d31;
            }
        }
        .cant-anuncios {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-top: 2rem;
            .anuncio-row {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-bottom: 10px;
                border-bottom: 1px dashed #eaeaea;
                padding: 0rem 1.4rem;
                padding-bottom: 12px;
                p {
                    font-family: $poppins-medium;
                    font-size: 15px;
                    line-height: 15px;
                    color: $negro;
                    margin: 0rem;
                    span {
                        margin-left: 4px;
                    }
                    img {
                        width: 26px;
                        margin-right: 4px;
                        vertical-align: middle;
                    }
                }
            }
        }
    }

    .anuncios {
        padding: 1rem;
        padding-top: 0rem;
        .anuncio-title {
            background-color: $azul;
            font-family: $poppins;
            font-size: 18px;
            line-height: 20px;
            color: white;
            margin: 1rem 0rem;
            margin-bottom: 0rem;
            padding: 1rem;
            text-transform: uppercase;
        }
        .anuncio-info {
            padding: 2rem 1rem;
            .anuncio-row {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-bottom: 26px;
                border-bottom: 1px dashed #eaeaea;
                padding-bottom: 12px;
                p {
                    font-family: $poppins;
                    font-size: 15px;
                    line-height: 15px;
                    color: $negro;
                    margin: 0rem;
                    &:last-of-type {
                        font-family: $poppins-semibold;
                    }
                }
            }
            .anuncio-ref {
                margin-bottom: 0rem;
                p:first-of-type {
                    margin-bottom: 6px;
                }
            }
        }
    }

    .logo-empresa {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        img {
            width: 50%;
            margin-left: auto;
            vertical-align: middle;
            border: 1px solid #cccccc;
        }
        .file-field {
            width: 50%;
            padding: 0rem 1rem;
        }
    }

    .intro-empresa {
        width: 100%;
        .info-repre {
            width: 100%;
        }
    }
